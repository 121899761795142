import "./ChatWindow.css";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  selectChatLog,
  updateChatLog,
  addChatLog,
} from "../../store/app.slice";
import {
  Job,
  setRecommendedJobs,
  setSelectedJobId,
  selectSelectedJobId,
  selectRecommendedJobs
} from "../../store/jobs.slice.ts";
import {
    selectWholeResume,
} from "../../store/resume.slice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ChatMessage from "../ChatMessage/ChatMessage";
import SendIcon from "../../assets/images/chat-send-icon.svg";
import sendMessages, { Action } from "../../services/WebsocketManager.ts";
import feedbackIcon from "src/assets/images/chat_paste_go.svg";
import infoIcon from "src/assets/images/info.svg";
import fullscreenIcon from "src/assets/images/fullscreen.svg";
import closeIcon from "src/assets/images/close.svg";
import manpowerIcon from "src/assets/images/colored_brandig.svg";
import ChatJobCard from "../ChatCards/ChatJobCard.tsx";
import ChatDescriptionCard from "src/components/ChatCards/ChatDescriptionCard.tsx";
import { useSession } from "src/context/SessionProvider.tsx";
import mockJob from './mockJobData.json';

const ChatWindow = ({
  chatWindowOpen,
  toggleChatWindow,
  userInitials,
}: any) => {
  const [currentMessage, setCurrentMessage] = useState("");
  const { sessionID, wsMessage } = useSession();
  const [loadingChatResponse, setLoadingChatResponse] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const chatLog = useSelector(selectChatLog);

  const cv: any = useSelector(selectWholeResume);
  const jobId: number = useSelector(selectSelectedJobId);
  const recommendedJobs: Job[] = useSelector(selectRecommendedJobs);

  useEffect(() => {
    if (wsMessage) {
      if (wsMessage.jobs && wsMessage.jobs.length > 0) {
        dispatch(setRecommendedJobs(wsMessage.jobs));
        dispatch(setSelectedJobId(wsMessage.jobs[0].id));
        if (wsMessage.jobs.length === 1) {
          const jobMessage = {
            role: "assistant",
            type: "descriptionCard", // Changed to 'descriptionCard' for exactly one job
            ...wsMessage.jobs[0],
          };
          dispatch(addChatLog(jobMessage));
        } else {
          // Dispatch each job to the chat log
          wsMessage.jobs.forEach((job: any) => {
            const jobMessage = {
              role: "assistant",
              type: "card",
              ...job,
            };
            dispatch(addChatLog(jobMessage));
          });
        }
        setLoadingChatResponse(false);
      }

      if (
        typeof wsMessage.message === "string" ||
        wsMessage.message instanceof String
      ) {
        // Always add the response message last
        const newResponse = { role: "assistant", content: wsMessage.message };
        dispatch(addChatLog(newResponse));
        setLoadingChatResponse(false);
      }
    }
  }, [wsMessage]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chatLog]);

  const updateMessage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMessage(e.target.value);
  };

  function packageAndSendMessages(message: string): void {
    const selectedJobDetails = recommendedJobs.filter((job: Job) => job.id === jobId)[0]

    const messageData = {
      action: "sendMessage",
      message: message,
      session_id: sessionID,
      payload: {
        "job_description": `${selectedJobDetails.title} - ${selectedJobDetails.salary_range}\n\n${selectedJobDetails.about_this_role}\n\nResponsibilities\n${selectedJobDetails.responsibilities?.join('\n')}`,
        "cv": `${cv.name}\n\n${cv.location} | ${cv.phone} | ${cv.email}\n\nSummary:\n${cv.summary}\n\nEducation:\n\n${cv.education.map((edu: any) => {return `${edu.name}\n${edu.location}`}).join('\n\n')}\n\nExperience:\n\n${cv.experience.map((exp: any) => {return `${exp.title}\n${exp.timeline}\n${exp.location}\n\n${exp.details.join('\n')}`})}`,
        "company_des": `${selectedJobDetails.company_name || selectedJobDetails.company}\n${selectedJobDetails.location}\n\n${selectedJobDetails.about_us}`,
        "company_reviews": mockJob.company_reviews,
        "wait_time": mockJob.wait_time,
        "company_process": mockJob.company_process,
        "company_resources": mockJob.company_resources,
        // "job_listings": recommendedJobs.map((job) => {
        //   return `${job.title}\n\n${job.company_name || job.company} ${job.location}\n\n${job.salary_range}\n\nJob description\n\n${job.about_this_role}`
        // }),
        "job_events": mockJob.job_events,
      }
    };
    sendMessages(Action.CHAT, messageData);
    setCurrentMessage("");
  }

  function handleJobCardClick(job: Job): void {
    const jobMessage = {
      ...job,
      role: "assistant",
      type: "descriptionCard", // Changed to 'descriptionCard' for exactly one job
    };
    dispatch(addChatLog(jobMessage));
    dispatch(setSelectedJobId(job.id))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!currentMessage.trim()) return; // Prevent sending empty messages

    const newUserMessage = { role: "user", content: currentMessage };
    dispatch(addChatLog(newUserMessage));
    setLoadingChatResponse(true);
    packageAndSendMessages(currentMessage);
  };
  return (
    <>
      <div
        className={chatWindowOpen ? "chat-window-open" : "chat-window-closed"}
      >
        {/* Chat Header */}
        <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200 bg-white">
          <div className="flex items-center">
            <img src={manpowerIcon} alt="Chat AI Logo" className="h-4" />{" "}
            <h3 className="text-base font-semibold text-black ml-1">Chat AI</h3>
          </div>

          {/* Center Button */}
          <button className="flex items-center ml-16 px-2 py-0.5 text-sm text-gray-700 bg-white border rounded focus:outline-none focus:border-gray-400 hover:bg-gray-300">
            <span className="font-normal leading-6 text-xs">Feedback</span>{" "}
            <img src={feedbackIcon} alt="Feedback" className="w-5 h-5 mr-1" />{" "}
          </button>
          <div className="flex items-center">
            <button className="mr-2 focus:outline-none">
              <img src={infoIcon} alt="Info" className="h-5 w-5" />{" "}
            </button>
            <button className="mr-2 focus:outline-none">
              <img src={fullscreenIcon} alt="Fullscreen" className="h-5 w-5" />{" "}
            </button>
            <button onClick={toggleChatWindow} className="focus:outline-none">
              <img src={closeIcon} alt="closeChat" />
            </button>
          </div>
        </div>
        <div className="chat-content flex-grow overflow-auto relative">
          <div
            className="chat-content-container"
            id="chatContainer"
            ref={chatContainerRef}
          >
            {chatLog.length == 1 ? (
              <div className="flex flex-col mb-12 items-center justify-center">
                <img
                  className="w-11 h-6 mb-4"
                  src={manpowerIcon}
                  alt="Manpower Logo"
                />
                <p className="text-xl font-semibold text-black">
                  Hi, Jacqueline!
                </p>{" "}
                <p className="text-base text-gray-600">
                  How can I help you today?
                </p>
              </div>
            ) : null}
            {chatLog &&
              chatLog.map((item: any, idx: number) => {
                if (!item) return null;

                if (item.type) {
                  switch (item.type) {
                    case "card":
                      return (
                        <ChatJobCard
                          handleJobCardClick={handleJobCardClick}
                          key={idx}
                          job={item}
                          toggleChatWindow={toggleChatWindow}
                        />
                      );
                    case "descriptionCard":
                      return (
                        <ChatDescriptionCard
                          key={idx}
                          job={item}
                          toggleChatWindow={toggleChatWindow}
                          setLoadingChatResponse={setLoadingChatResponse}
                          packageAndSendMessages={packageAndSendMessages}
                        />
                      );
                    default:
                      return (
                        <ChatMessage
                          key={idx}
                          text={item.content}
                          role={item.role}
                          userInitials={userInitials}
                        />
                      );
                  }
                } else {
                  return (
                    <ChatMessage
                      key={idx}
                      text={item.content}
                      role={item.role}
                      userInitials={userInitials}
                    />
                  );
                }
              })}
            {loadingChatResponse && (
              <div className="loading-chat">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  color="#5e6a9a"
                  size="lg"
                  className="loading-spinner"
                />
              </div>
            )}
          </div>
          {/* Buttons container */}
          {/* <div className="absolute bottom-0 left-0 right-0 px-4 flex">
            {" "}
            <button className="text-sm border border-blue-200 bg-[var(--Accent-Blue-Light,#E5ECF6)] text-blue-700 rounded-full px-4 py-2 mr-1">
              Update my resume
            </button>
            <button className="text-sm border border-orange-200 bg-[var(--Accent-Orange-Light,#FCF1EA)] text-orange-700 rounded-full px-4 py-2">
              Help me apply
            </button>
          </div> */}
        </div>

        <div className="chat-footer px-4 pb-1">
          <form className="chat-form" onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                className="user-input-box text-black"
                placeholder="Ask something..."
                value={currentMessage}
                onChange={updateMessage}
              ></input>
              <button className="send-message-button">
                <img src={SendIcon} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChatWindow;
