import { configureStore } from '@reduxjs/toolkit';
import appReducer from './app.slice';
import resumeReducer from './resume.slice';
import jobsReducer from './jobs.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    resume: resumeReducer,
    jobs: jobsReducer
  },
});


export default store;