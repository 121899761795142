import "./ChatMessage.css";
import iconAI from "../../assets/images/chat-button.svg";
import manpowerIcon from "src/assets/images/colored_brandig.svg";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from 'remark-gfm'
// import rehypeRaw from "rehype-raw";

const ChatMessage = ({
  text,
  role,
  userInitials,
}: {
  text: string;
  role: string;
  userInitials: string;
}) => {
  return (
    <>
      {role == "assistant" && (
        <div className="text-row bot-text-row">
          <div className="icon-container">
            <div className="ai-icon">
              <img src={manpowerIcon} alt="" />
            </div>
          </div>
          <div className="bot-text-bubble whitespace-pre-wrap">
            {text}
            {/* <ReactMarkdown
              children={text}
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]} /> */}
          </div>
          <div className="icon-container">
            <div className="empty-space"></div>
          </div>
        </div>
      )}
      {role == "user" && (
        <div className="text-row user-text-row">
          <div className="icon-container">
            <div className="empty-space"></div>
          </div>
          <div className="user-text-bubble ">
            <p className="text-bubble-text">{text}</p>
          </div>
          <div className="icon-container">
            <div className="user-icon text-sm">You</div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatMessage;
