// Imports
import React, { useEffect } from "react"
import ResumeSection from "./ResumeSection";
import {
    selectEducation,
    selectExperience,
    selectSkills,
    selectSummary,
    selectName,
    selectLocation,
    selectPhone,
    selectEmail,
    selectWholeResume,
    setSummary,
    setSkills,
    setExperience,
    setEducation,
    setName,
    setLocation,
    setPhone,
    setEmail
} from "../../store/resume.slice";
import {
    selectSelectedJobId
} from "../../store/jobs.slice";
import { useSelector, useDispatch } from "react-redux";

// Component for the Resume Card
function ResumeCard(props: any) {
    const jobId: number = useSelector(selectSelectedJobId);
    const summary: string = useSelector(selectSummary);
    const skills: string[] = useSelector(selectSkills)
    const experience: any[] = useSelector(selectExperience);
    const education: any[] = useSelector(selectEducation)
    const name: string = useSelector(selectName);
    const location: string = useSelector(selectLocation);
    const phone: string = useSelector(selectPhone);
    const email: string = useSelector(selectEmail);
    const cv: any = useSelector(selectWholeResume);
    const dispatch = useDispatch();

    const { setResumeSaved, newResume } = props;

    // Handler function when top info changes
    const handleInfoChange = (event: any, section: string) => {
        switch (section) {
            case "name":
                dispatch(setName(event.target.value));
                break;
            case "location":
                dispatch(setLocation(event.target.value));
                break;
            case "phone":
                dispatch(setPhone(event.target.value));
                break;
            case "email":
                dispatch(setEmail(event.target.value));
                break;
            default:
                break;
        }
    }

    // Use effect for when a new Resume
    useEffect(() => {
        if (newResume) {
            dispatch(setName(""));
            dispatch(setLocation(""));
            dispatch(setPhone(""));
            dispatch(setEmail(""));
            dispatch(setSummary(""));
            dispatch(setSkills([""]));
            dispatch(setExperience([
                {
                    title: "",
                    timeline: "",
                    location: "",
                    details: [""]
                },
                {
                    title: "",
                    timeline: "",
                    location: "",
                    details: [""]
                }
            ]));
            dispatch(setEducation([
                {
                    name: "",
                    location: ""
                },
                {
                    name: "",
                    location: ""
                }
            ]))
        }
    }, [newResume]);

    // Function to determine if resume is valid
    const validResume = () => {
        // Valid Details
        if (!name || !phone || !location || !email) return false;
        // Valid Summary
        if (!summary) return false;
        // Valid Skills
        if (!skills.some((skill: string) => {
            return skill && skill !== "";
        })) return false;
        // Valid Experience
        if (!experience.some((experienceItem: any) => {
            const validData = experienceItem.title && experienceItem.timeline && experienceItem.location;
            const validDetails = experienceItem.details.some((bullet: string) => {
                return bullet && bullet !== "";
            })
            return validData && validDetails;
        })) return false;
        // Valid Education
        if(!education.some((educationItem: any) => {
            return educationItem.name && educationItem.name !== "" && educationItem.location && educationItem.location !== "";
        })) return false;
        // Passed all validations
        return true;
    }

    return (
        <div className="resumeCard bg-white border-[1px] border-accent-green rounded-lg flex flex-col items-start">
            {/* Resume Content */}
            <div className="resumeContentContainer p-10 flex flex-col w-full">
                {/* Profile Section */}
                <div className="profileSection flex flex-row justify-start gap-5">
                    <div className="imageContainer w-16 h-16 bg-neutral-600 rounded-lg"></div>
                    <div className="profileInfo flex flex-col justify-between items-start">
                        <input className="profileNameContainer w-full font-semibold text-2xl text-accent-green" value={name} placeholder="Full Name" onChange={(event) => handleInfoChange(event, "name")}/>
                        <div className="profileContact flex flex-row font-medium text-xs text-neutral-700 flex-wrap">
                            <input className="profileContactSection w-1/2" value={phone} placeholder="555-555-5555" onChange={(event) => handleInfoChange(event, "phone")}/>
                            <input className="profileContactSection w-1/2" value={email} placeholder="sample@email.com" onChange={(event) => handleInfoChange(event, "email")}/>
                            <input className="profileContactSection w-full" value={location} placeholder="City, State" onChange={(event) => handleInfoChange(event, "location")}/>
                        </div>
                    </div>
                </div>
                {/* Summary */}
                <ResumeSection header="Summary" resumeSection="summary" content={summary} jobId={jobId} setter={setSummary} cv={cv}/>
                {/* Skills */}
                <ResumeSection header="Skills" resumeSection="skills" content={skills} jobId={jobId} setter={setSkills} cv={cv}/>
                {/* Work Experience */}
                <ResumeSection header="Work Experience" resumeSection="experience" content={experience} jobId={jobId} setter={setExperience} cv={cv}/>
                {/* Education and Certifications */}
                <ResumeSection header="Education and Certifications" resumeSection="education" content={education} hideEditables={true} setter={setEducation}/>
            </div>
            {/* Resume Card Footer */}
            <div className="resumeFooter w-full h-[88px] shadow-[0_-2px_4px_0_rgba(50,50,71,0.06)] px-9 py-8 flex flex-row items-center justify-between text-nowrap">
                {validResume() ? 
                    <div className="leftButton font-semibold text-base text-neutral-900 text-left">Resume looks good!</div>
                    : <div></div>
                }
                <div className="rightButtons flex flex-row w-fit justify-end gap-4">
                    {/* <div className="deleteBtn h-10 py-2 px-4 font-medium text-neutral-900 text-sm text-center flex justify-center items-center cursor-not-allowed">Delete</div> */}
                    {/* <div className="laterBtn h-10 py-2 px-4 font-semibold text-sm text-center border-[1px] border-brand-green rounded-lg text-accent-green cursor-not-allowed">Save for later</div> */}
                    <div 
                        className={`saveBtn h-10 py-2 px-8 font-medium text-white text-sm text-center rounded-lg 
                        ${validResume()? 'bg-accent-green cursor-pointer' : 'bg-neutral-700 cursor-not-allowed'}`}
                        onClick={() => {if(validResume()) setResumeSaved(true)}}
                    >Save and Apply</div>
                </div>
            </div>
        </div>
    )
}

export default ResumeCard;