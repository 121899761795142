import React, { FC, useState } from "react";
import sort_logo from "../../assets/images/swap_vert.svg";
import add_app from "../../assets/images/add_circle.svg";
import filter_logo from "../../assets/images/filter_list.svg";
import affinitive_logo from "../../assets/images/affinitive_logo.png";
import willcox_logo from "src/assets/images/willcox_logo.svg";
import sage_ellipse from "../../assets/images/sage_ellipse.svg";
import gray_ellipse from "../../assets/images/gray_ellipse.svg";
import titan_logo from "src/assets/images/titan_logo.svg";
import check_small from "src/assets/images/check_small.svg";

const applications = [
  {
    company: "Affinitiy",
    companyLogo: affinitive_logo,
    role: "Warehouse Operations Administrator",
    payRange: "$65,000 - $75,000",
    location: "Aurora, IL",
    status: {
      applied: {
        done: true,
        date: "3/26/2024",
      },
      screen: {
        done: false,
        date: "",
      },
      interview: {
        done: false,
        date: "",
      },
      other: {
        done: false,
        date: "",
      },
    },
  },
  {
    company: "Willcox Matthews",
    companyLogo: willcox_logo,
    role: "Warehouse Supervisor",
    payRange: "$48,000 - $58,000",
    location: "Schaumburg, IL",
    status: {
      applied: {
        done: true,
        date: "4/01/2024",
      },
      screen: {
        done: true,
        date: "4/08/2024",
      },
      interview: {
        done: false,
        date: "",
      },
      other: {
        done: false,
        date: "",
      },
    },
  },
  {
    company: "Tech Titans ",
    companyLogo: titan_logo,
    role: "Inventory Control Lead ",
    payRange: "$60,000 - $70,000",
    location: "Chicago, IL",
    status: {
      applied: {
        done: true,
        date: "3/26/2024",
      },
      screen: {
        done: false,
        date: "",
      },
      interview: {
        done: false,
        date: "",
      },
      other: {
        done: false,
        date: "",
      },
    },
  },
];

interface ApplicationStatus {
  applied: {
    done: boolean;
    date: string;
  };
  screen: {
    done: boolean;
    date: string;
  };
  interview: {
    done: boolean;
    date: string;
  };
  other: {
    done: boolean;
    date: string;
  };
}

interface ApplicationProps {
  company: string;
  companyLogo: string;
  role: string;
  payRange: string;
  location: string;
  status: ApplicationStatus;
}

const ApplicationDetails: FC<ApplicationProps> = ({
  company,
  role,
  companyLogo,
  payRange,
  location,
  status,
}): JSX.Element => {
  return (
    <div className="flex flex-row justify-between max-w-full bg-white p-8 rounded-2xl">
      <div className="flex flex-col">
        <div className="relative">
          <img className="h-8 w-8" src={companyLogo} alt="Company Logo" />
          <img
            src={check_small} // The path to your second image
            className="absolute inset-0 m-auto h-5 w-4 z-10"
            alt="Overlay Icon"
            style={{ maxWidth: "50%", maxHeight: "50%" }}
          />
        </div>
        <p className="font-semibold text-base mt-3">{role}</p>
        <div className="flex flex-row gap-4 mt-5">
          <div>
            <p className="font-semibold bg-neutral-100 py-2 px-4 rounded">
              {payRange}
            </p>
          </div>
          <div>
            <p className="font-semibold bg-neutral-100 py-2 px-4 rounded">
              {location}
            </p>
          </div>
        </div>
      </div>
      <ApplicationStatusBar
        applied={status.applied}
        screen={status.screen}
        interview={status.interview}
        other={status.other}
      />
    </div>
  );
};

const ApplicationStatusBar: FC<ApplicationStatus> = ({
  applied,
  screen,
  interview,
  other,
}): JSX.Element => {
  return (
    <div className="flex flex-row items-center">
      {applied.done && (
        <div className="flex flex-col items-center text-center">
          <p className="m-1">Applied</p>
          <img className="w-9 h-9" src={sage_ellipse} alt="Applied Status" />
          <p className="m-1">{applied.date || ""}</p>
        </div>
      )}
      {!applied.done && (
        <div className="flex flex-col items-center text-center">
          <p className="m-1">Applied</p>
          <img className="w-9 h-9" src={gray_ellipse} alt="Applied Status" />
          <p className="h-6 m-1"> </p>
        </div>
      )}
      <div className="flex flex-grow items-center justify-center">
        <hr className="border-t w-8 border-gray-300" />
      </div>
      {screen.done && (
        <div className="flex flex-col items-center text-center">
          <p className="m-1">Screened</p>
          <img className="w-9 h-9" src={sage_ellipse} alt="Screened Status" />
          <p className="m-1">{screen.date || ""}</p>
        </div>
      )}
      {!screen.done && (
        <div className="flex flex-col items-center text-center">
          <p className="m-1">Screened</p>
          <img className="w-9 h-9" src={gray_ellipse} alt="Screened Status" />
          <p className="h-6 m-1"> </p>
        </div>
      )}
      <div className="flex flex-grow items-center justify-center">
        <hr className="border-t w-8 border-gray-300" />
      </div>
      {interview.done && (
        <div className="flex flex-col items-center text-center">
          <p className="m-1">Interviewed</p>
          <img
            className="w-9 h-9"
            src={sage_ellipse}
            alt="Interviewed Status"
          />
          <p>{interview.date || ""}</p>
        </div>
      )}
      {!interview.done && (
        <div className="flex flex-col items-center text-center">
          <p className="m-1">Interviewed</p>
          <img
            className="w-9 h-9"
            src={gray_ellipse}
            alt="Interviewed Status"
          />
          <p className="h-6 m-1"> </p>
        </div>
      )}
      <div className="flex flex-grow items-center justify-center">
        <hr className="border-t w-8 border-gray-300" />
      </div>
      {other.done && (
        <div className="flex flex-col items-center text-center">
          <p className="m-1">Other</p>
          <img className="w-9 h-9" src={sage_ellipse} alt="Other Status" />
          <p>{other.date || ""}</p>
        </div>
      )}
      {!other.done && (
        <div className="flex flex-col items-center text-center">
          <p className="m-1">Other</p>
          <img className="w-9 h-9" src={gray_ellipse} alt="Other Status" />
          <p className="h-6 m-1"> </p>
        </div>
      )}
    </div>
  );
};

function ApplicationTracker() {
  return (
    <div className="m-auto w-4/5 text-left flex flex-col gap-8">
      <div className="flex flex-row justify-between mt-20">
        <p className="font-semibold text-xl">Application Tracker</p>
        <div className="ml-auto flex flex-row gap-5">
          <div className="flex flex-row gap-2 items-center">
            <img src={filter_logo} />
            <p className="font-semibold text-sm">Filter</p>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <img src={sort_logo} />
            <p className="font-semibold text-sm">Sort</p>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <img src={add_app} />
            <p className="font-semibold text-nowrap text-sm">Add Application</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-8 mb-12">
        {applications.map((item, idx) => {
          return (
            <ApplicationDetails
              company={item.company}
              companyLogo={item.companyLogo}
              role={item.role}
              payRange={item.payRange}
              location={item.location}
              status={item.status}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ApplicationTracker;
