import React from "react";
import profile_pic from "src/assets/images/avatar.png";
import { useNavigate } from "react-router-dom";

export function Profile() {
  const navigate = useNavigate();

  return (
    <div className="m-6 space-y-8">
      <div className="bg-white rounded-lg p-8 w-[20.25rem] h-[32.8125rem]">
        {/* Profile picture at the top */}
        <div className="flex flex-col items-center">
          <img
            className="h-16 w-16 rounded-full mb-3 mt-[-8px]"
            src={profile_pic}
            alt="Profile"
          />
          <p className="text-gray-900 text-xl font-semibold">Jacqueline S.</p>
          <p className="text-gray-600 text-sm">Production Worker</p>

          <div className="mt-3.5 flex justify-center items-center text-xs gap-2 w-40 py-2 px-4 border border-green-400 rounded-full bg-white">
            Available for work
          </div>
        </div>

        <div className="mt-12 mb-5 flex items-start">
          <p className="text-neutral-900 text-base font-semibold">
            Skills and Expertise
          </p>
        </div>

        {/* Tags list */}
        <div className="flex flex-wrap mb-4">
          {/* Tags */}
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 mb-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            Materials Handling
          </span>
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 mb-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            Trucking
          </span>
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 mb-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            Visual Inspector
          </span>
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 mb-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            Caliper
          </span>
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 mb-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            Stacking
          </span>
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 mb-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            Inventory Control
          </span>
        </div>
        {/* Edit Profile button */}
        <div className="self-center mt-12">
          <button className="flex w-64 justify-center items-center gap-2 py-2.5 px-14 text-sm rounded bg-neutral-900 text-white">
            Edit Profile
          </button>
        </div>
      </div>
      {/* New Card 1 */}
      <div className="bg-white rounded-lg p-8 w-[20.25rem] h-[14rem]">
        <div className="mt-1 mb-5 flex items-start">
          <p className="text-neutral-900 text-xl font-semibold">
            Improve your resume
          </p>
        </div>
        <div className="text-left">
          <p className="text-gray-700 text-base">
            Lets improve your resume to help you land your next job opportunity.
          </p>
        </div>
        <div className="self-center mt-8">
          <button 
            className="flex w-64 justify-center items-center gap-2 py-2.5 px-14 text-sm rounded bg-[#3B8160] text-white"
            onClick={() => navigate("/resume")}>
            Show me how
          </button>
        </div>
      </div>
      <div className="bg-white rounded-lg p-8 w-[20.25rem] h-[14rem]">
        <div className="mt-1 mb-5 flex items-start">
          <p className="text-neutral-900 text-xl font-semibold">
            Improve your resume
          </p>
        </div>
        <div className="text-left">
          <p className="text-gray-700 text-base">
            Set up job alerts and get notified when job postings match your
            preferences.
          </p>
        </div>
        <div className="text-left mt-4">
          <a href={""} className="text-indigo-600 hover:underline">
            Job alerts
          </a>
        </div>
      </div>
    </div>
  );
}
