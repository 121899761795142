import React, { useState } from "react";
import { Profile } from "./Profile";
import { JobsSection } from "./JobsSection";

function Home() {
  return (
    <div className="flex flex-no-wrap mx-auto max-w-full p-16 bg-gray-100">
      {/* Profile section */}
      <Profile />

      {/* Job Cards section */}
      <div className="flex flex-wrap justify-start ml-8">
        <JobsSection></JobsSection>
      </div>
    </div>
  );
}

export default Home;
