import React from "react";
import {
    selectRecommendedJobs,
    selectSelectedJobId,
    Job
} from "../../store/jobs.slice";
import { useSelector } from "react-redux";
import medline_logo from "src/assets/images/medline-industries-logo.svg";
import affinity_logo from "src/assets/images/affinity-logo.svg";
import jd_logo from "src/assets/images/jd-logo.svg";
import manpower_logo from "src/assets/images/manpower_logo.png";

const logoMap: any = {
  Affinity: affinity_logo,
  "Medline Industries": medline_logo,
  "Willcox Matthews": jd_logo, // Assuming 'Willcox Matthews' should map to the 'jd-logo'
  "Manpower": manpower_logo,
  "default_logo_path": manpower_logo
};

function RoleCard() {
    const jobId: number = useSelector(selectSelectedJobId);
    const recommendedJobs: Job[] = useSelector(selectRecommendedJobs);
    
    const selectedJobDetails = recommendedJobs.filter((job: Job) => job.id === jobId)[0]
    const companyLogo = logoMap[selectedJobDetails.company_name] || logoMap[selectedJobDetails.company? selectedJobDetails.company : "default_logo_path"] || logoMap["default_logo_path"]; // replace with a default logo if needed

    return (
        <div className="roleCard w-full min-w-[400px] h-fit bg-white rounded-lg flex flex-col justify-start items-start p-6 pb-16">
            {/* Company Section */}
            <div className="companySection flex flex-row justify-start items-center gap-2 font-medium text-sm text-neutral-700">
                <img
                    src={companyLogo}
                    alt={`${selectedJobDetails.company_name} Logo`}
                    className="w-8 h-8 object-cover rounded-lg border-[1px] border-neutral-300"
                />
                {selectedJobDetails.company_name}
            </div>
            {/* Title / Role */}
            <div className="titleSection mt-5 font-semibold text-base text-neutral-900 text-left">
                {selectedJobDetails.title}
            </div>
            {/* Role Details */}
            <div className="roleDetails mt-4 flex flex-col gap-3 w-full">
                {/* Company Row */}
                <div className="roleDetailsRow w-full flex flex-row text-left font-normal text-xs text-neutral-700">
                    <div className="roleDetailsKey w-1/2 text-black font-medium">Company:</div>
                    <div className="roleDetailsValue w-1/2">{selectedJobDetails.company_name || selectedJobDetails.company}</div>
                </div>
                {/* Location Row */}
                <div className="roleDetailsRow w-full flex flex-row text-left font-normal text-xs text-neutral-700">
                    <div className="roleDetailsKey w-1/2 text-black font-medium">Location:</div>
                    <div className="roleDetailsValue w-1/2">{selectedJobDetails.location}</div>
                </div>
                {/* Salary Range Row */}
                <div className="roleDetailsRow w-full flex flex-row text-left font-normal text-xs text-neutral-700">
                    <div className="roleDetailsKey w-1/2 text-black font-medium">Salary Range:</div>
                    <div className="roleDetailsValue w-1/2">{selectedJobDetails.salary_range}</div>
                </div>
                {/* About Company */}
                <div className="roleDetailsRow w-full flex flex-col text-left font-normal text-xs text-neutral-700 gap-3 mt-1">
                    <div className="roleDetailsKey text-black font-medium">About us:</div>
                    <div className="roleDetailsValue">{selectedJobDetails.about_us}</div>
                </div>
                {/* About Role */}
                <div className="roleDetailsRow w-full flex flex-col text-left font-normal text-xs text-neutral-700 gap-3 mt-1">
                    <div className="roleDetailsKey text-black font-medium">About this role:</div>
                    <div className="roleDetailsValue">{selectedJobDetails.about_this_role}</div>
                </div>
                {/* Responsibilities */}
                <div className="roleDetailsRow w-full flex flex-col text-left font-normal text-xs text-neutral-700 gap-3 mt-1">
                    <div className="roleDetailsKey text-black font-medium">Responsibilities:</div>
                    <ul className="skillsList font-normal text-neutral-700 text-left text-xs">
                        {selectedJobDetails.responsibilities?.map((skill: string, index: number) =>
                            <li className="flex flex-row justify-start items-start gap-2" key={`skill-${index}`}><span className="font-semibold">&#8226;</span> {skill}</li>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default RoleCard;