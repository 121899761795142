import React, { useState } from "react";
import Search from "./Search";
import logos from "../../assets/images/PlaceholderLogos.png";
import blue_ellipse from "../../assets/images/Ellipse4.svg";
import orange_ellipse from "../../assets/images/Ellipse2.svg";
import light_ellipse from "../../assets/images/Ellipse1.svg";
import green_ellipse from "../../assets/images/Ellipse3.png";
import blue_img from "../../assets/images/image74.png";
import orange_img from "../../assets/images/image742.png";
import light_blue_img from "../../assets/images/image743.png";
import green_img from "../../assets/images/image745.png";

function Header() {
  
  return (
    <div className="relative max-w-full p-24 bg-gray-100">
      <img className="absolute top-12 left-48" src={blue_ellipse} />
      <img className="absolute top-16 left-52" src={blue_img} />
      <p className="text-5xl font-bold pb-3">Find Your huManpower </p>
      <p className="text-2xl font-semibold pb-8 text-accent-blue">Find Your Perfect Job</p>
      <img className="absolute top-12 right-48" src={orange_ellipse} />
      <img className="absolute top-16 right-52" src={orange_img} />
      <img className="absolute bottom-36 left-16" src={light_ellipse} />
      <img className="absolute bottom-40 left-24" src={light_blue_img} />
      <img className="absolute bottom-28 right-20" src={green_ellipse} />
      <img className="absolute bottom-36 right-28" src={green_img} />
      <Search />
      <div className="flex flex-col gap-6 w-3/5 mb-8 mt-8 m-auto">
        <h2 className="text-neutral-900 font-semibold">Big companies are here</h2>
        <h4 className="font-normal">Our platform connects you with a variety of top companies. This means you'll gain experience in different work environments, learn the latest industry trends, and tackle exciting projects. It's a win-win: you build a strong resume that stands out, and you discover what you love to do – all while launching a successful career.</h4>
      </div>
      <img className="w-3/5 m-auto" src={logos} />
    </div>
  );
}
export default Header;