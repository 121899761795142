import { w3cwebsocket as W3CWebSocket } from "websocket";
import { selectChatLog, updateChatLog } from "../store/app.slice";
const url = "wss://da2z3mdo9c.execute-api.us-east-1.amazonaws.com/dev";
const timeout = 540000;

export const socket = new W3CWebSocket(url);

export enum Action {
  CHAT = "chat",
  TEST = "test",
  RESUME = "resume"
}

interface MessageData {
  action: string;
  message?: any;
  session_id: string;
  job_id?: number;
  resume_section?: string;
  cv?: any;
  job_title?: string;
  job_description?: string;
}

function sendMessages(action: Action, message: MessageData) {
  if (socket.readyState !== socket.OPEN) {
    console.log("socket not open");
    setTimeout(() => {sendMessages(action, message)}, 2000);
    return;
  }
  try {
    socket.send(JSON.stringify(message));
  } catch (error) {
    console.log("error sending message", error);
  }
}

export function initiateWebsocketConnection() {
  socket.onopen = () => {
    console.log("WebSocket Client Connected");
  };
}

export function restartWebsocket() {
  socket.close();
  socket.onopen = () => {
    console.log("WebSocket Client Connected");
  };
}

export default sendMessages;
