// Imports
import React, { useEffect, useRef, useState } from "react";
import check_small from '../../assets/images/check_small.svg';
import cached from '../../assets/images/cached.svg';
import undo from '../../assets/images/undo.svg';
// import deleteIcon from '../../assets/images/delete.svg';
import branding from '../../assets/images/brandig_green.svg';
import sendMessages, {
  Action,
} from "../../services/WebsocketManager.ts";
import { useSession } from "src/context/SessionProvider.tsx";
import { useDispatch } from "react-redux";
import ReactDiffViewer, {DiffMethod} from 'react-diff-viewer';

// Component to show and handle sections in the Resume
function ResumeSection(props: {header: string, content: any, resumeSection: string; hideEditables?: boolean, jobId?: number, setter?: any, cv?: any}) {
    const { header, content, hideEditables, jobId, resumeSection, setter, cv } = props;
    const { sessionID, wsMessage } = useSession();
    const dispatch = useDispatch();
    // To hold the regenerated version of the content (if any)
    const [updatedContent, setUpdatedContent] = useState<any>(null);
    // For a loading state when content is regenerating
    const [regenerating, setRegenerating] = useState<boolean>(false);
    // To keep track if we triggered the generate / customize for the section
    const [customized, setCustomized] = useState<boolean>(false);
    // To avoid rendering issues
    const hasComponentBeenRendered = useRef(false);
    // To determine if to show diff block
    const [showDiff, setShowDiff] = useState<boolean>(false);

    // Function to handle regenerating the content of the section
    const handleRegenerate = () => {
        setRegenerating(true); // Start loading

        // Construct request object
        const messageData = {
            action: 'sendMessage',
            job_id: jobId,
            resume_section: resumeSection,
            message: JSON.stringify(cv).replaceAll("\"", "'"),
            session_id: sessionID
        };
        sendMessages(Action.RESUME, messageData)
    }

    // Function to handle reverting the regeneration to initial state
    const handleRevertChanges = () => {
        setUpdatedContent(null);
        setCustomized(false);
    }

    // Function to handle keeping the updated version of content
    const handleKeep = () => {
        dispatch(setter(updatedContent)) // Update the value in store
        setUpdatedContent(null) // Clear updated content
        setCustomized(false);
    }

    // Function to handle a change in a text area
    const handleTextAreaChange = (event: any, index?: number, subIndex?: number, section?: string) => {
        if (resumeSection === 'summary')
            setUpdatedContent(event.target.value);
        if (resumeSection === 'skills' && (index === 0 || index)) {
            let tempContent = [...updatedContent || content];
            tempContent[index] = event.target.value.replaceAll('\n', '');
            setUpdatedContent(tempContent);
        }
        if (resumeSection === 'experience' && (index === 0 || index)) {
            if (section) {
                let tempContent = [...updatedContent || content];
                tempContent[index] = {...tempContent[index], [section]: event.target.value.replaceAll('\n', '')};
                setUpdatedContent(tempContent);
            }
            if (subIndex === 0 || subIndex) {
                let tempContent = [...updatedContent || content];
                let tempDetails = [...tempContent[index]['details']]
                tempDetails[subIndex] = event.target.value.replaceAll('\n', '');
                tempContent[index] = { ...tempContent[index], details: tempDetails};
                setUpdatedContent(tempContent);
            }
        }
        if (resumeSection === 'education') {
            if ((index === 0 || index) && section) {
                let tempContent = [...content];
                tempContent[index] = { ...tempContent[index], [section]: event.target.value.replaceAll('\n', '')};
                dispatch(setter(tempContent))
            }
        }
    }

    // Function to handle adding a new item to list
    const handleNewLine = (event: any, index: number, subIndex?: number) => {
        // Add New Skill
        if (resumeSection === 'skills' && event.key === "Enter") {
            let tempContent = [...updatedContent || content];
            tempContent.splice(index+1, 0, "");
            setUpdatedContent(tempContent);
        }
        // Add New Experience Detail
        if (resumeSection === 'experience' && event.key === "Enter" && (subIndex === 0 || subIndex)) {
            let tempContent = [...updatedContent || content];
            let tempDetails = [...tempContent[index]['details']]
            tempDetails.splice(subIndex + 1, 0, "")
            tempContent[index] = { ...tempContent[index], details: tempDetails};
            setUpdatedContent(tempContent);
        }
        // Remove Skill Line
        if (resumeSection === "skills" && event.keyCode === 8 && event.target.value === ""){
            event.preventDefault();
            let tempContent = [...updatedContent || content];
            tempContent.splice(index, 1);
            setUpdatedContent(tempContent);
        }
        // Remove Experience Detail line
        if (resumeSection === "experience" && event.keyCode === 8 && event.target.value === "" && (subIndex === 0 || subIndex)){
            event.preventDefault();
            let tempContent = [...updatedContent || content];
            let tempDetails = [...tempContent[index]['details']]
            tempDetails.splice(subIndex, 1)
            tempContent[index] = { ...tempContent[index], details: tempDetails};
            setUpdatedContent(tempContent);
        }
    }

    // Use effect to catch web socket messages for this component
    useEffect(() => {
        if(hasComponentBeenRendered.current && wsMessage) {
            if(typeof wsMessage.message === 'object' && wsMessage.message[resumeSection]){
                // Update the section
                setUpdatedContent(wsMessage.message[resumeSection])
                setRegenerating(false)
                setCustomized(true);
            }
        }
        hasComponentBeenRendered.current = true;
    }, [wsMessage])

    // Function to format the experience object for the diff
    const formatExperienceDiff = (content: any) => {
        let outputStr = "";
        content.forEach((exp: any) => {
            const data = `- ${exp.title}, ${exp.timeline}\n${exp.location}\n`;
            const desc = [...exp.details].join("\n");
            outputStr = outputStr.concat(data, desc, "\n\n");
        })
        return outputStr;
    }

    // Function to check if empty work experiences' details
    const validWorkExperiences = () => {
        return content.some((experience: any) => {
            const validData = experience.title && experience.timeline && experience.location;
            const validDetails = experience.details.some((bullet: string) => {
                return bullet && bullet !== "";
            })
            return validData && validDetails;
        })
    }

    return (
        <div className="resumeSection flex flex-col w-full mt-12 gap-4">
            <div className="sectionHeader flex flex-row justify-between items-start border-b-[1px] border-accent-green h-8">
                <div className="sectionHeaderText font-semibold text-base">{header}</div>
                {customized && (
                    <div className="recPill flex flex-row justify-between items-center px-3 py-1 w-fit rounded-full bg-[#E8F6EF] font-medium text-[9px] text-accent-green gap-1">
                        <img className="h-[6px]" src={branding} alt="branding"/>
                        Recommended changes
                    </div>
                )}
            </div>
            {/* Content */}

            {/* If Summary */}
            { resumeSection === "summary" && (
                <textarea 
                    rows={5}
                    className="blockOfText font-normal text-sm text-left w-full"
                    value={updatedContent || updatedContent === ""? updatedContent : content}
                    onChange={handleTextAreaChange}
                    placeholder="A short summary describing your past experiences, skills, and interests."
                />
            )}
            {
                resumeSection === "summary" && updatedContent && showDiff && (
                    <div className="w-full text-left text-xs">
                        <ReactDiffViewer oldValue={content} newValue={updatedContent} splitView={false} hideLineNumbers={true} compareMethod={DiffMethod.WORDS}/>
                    </div>
                )
            }

            {/* If Skills */}
            { resumeSection === "skills" && (
                <ul className="skillsList text-sm font-normal text-neutral-700 text-left">
                    {(updatedContent || content).map((skill: string, index: number) =>
                        <li className="flex flex-row justify-start items-start gap-2" key={`skill-${index}`}>
                            <span className="font-semibold">&#8226;</span> 
                            <input className="w-full" value={skill} placeholder="Hard or soft skill" onChange={(e) => {handleTextAreaChange(e, index)}} onKeyDown={(e) => {handleNewLine(e, index)}} />
                        </li>
                    )}
                </ul>
            )}
            {
                resumeSection === "skills" && updatedContent && showDiff && (
                    <div className="w-full text-left text-xs">
                        <ReactDiffViewer oldValue={content.join('\n')} newValue={updatedContent.join('\n')} splitView={false} hideLineNumbers={true} compareMethod={DiffMethod.WORDS}/>
                    </div>
                )
            }

            {/* If Work Experience */}
            { resumeSection === "experience" && (
                (updatedContent || content).map((experience: any, index: number) => 
                    <div className="workExperience" key={`experience-${index}`}>
                        <div className="workExperienceHeader font-semibold text-sm text-neutral-900 text-left">
                            <div className="w-full flex flex-row items-center justify-between">
                                <input className="w-2/3" value={experience.title} placeholder="Job Title" onChange={(e) => {handleTextAreaChange(e, index, undefined, "title")}} />
                                <input className="w-1/3 text-right" value={experience.timeline} placeholder="From - Until" onChange={(e) => {handleTextAreaChange(e, index, undefined, "timeline")}} />
                            </div>
                            <input className="w-full" value={experience.location} placeholder="Company, City, State" onChange={(e) => {handleTextAreaChange(e, index, undefined, "location")}} />
                            <br></br>
                            <ul className="skillsList text-sm font-normal text-neutral-700 mt-2">
                                {experience.details.map((bullet: string, subIndex: number) => 
                                    <li className="flex flex-row justify-start items-start gap-2" key={`experience-${index}-detail-${subIndex}`}>
                                        <span className="font-semibold">&#8226;</span> 
                                        <textarea rows={2} className="w-full" value={bullet} placeholder="For example: I worked in warehouse as an operator and helped out with packaging" onChange={(e) => {handleTextAreaChange(e, index, subIndex)}} onKeyDown={(e) => {handleNewLine(e, index, subIndex)}} />
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                )
            )}
            {
                resumeSection === "experience" && updatedContent && showDiff && (
                    <div className="w-full text-left text-xs">
                        <ReactDiffViewer oldValue={formatExperienceDiff(content)} newValue={formatExperienceDiff(updatedContent)} splitView={false} hideLineNumbers={true} compareMethod={DiffMethod.WORDS}/>
                    </div>
                )
            }

            {/* If Education and Certifications */}
            { resumeSection === "education" && (
                content.map((education: any, index: number) => 
                    <div className="educationSection text-left text-neutral-900 font-normal text-sm" key={`education-${index}`}>
                        <input className="w-full font-semibold" value={education.name} placeholder="Diploma or Certificate" onChange={(e) => {handleTextAreaChange(e, index, undefined, "name")}}></input>
                        <br></br>
                        <input className="w-full" value={education.location} placeholder="School name, School location" onChange={(e) => {handleTextAreaChange(e, index, undefined, "location")}}></input>
                    </div>
                )
            )}

            {/* Buttons */}
            {!hideEditables && (
                !regenerating? (
                    <div className="sectionButtons flex flex-row justify-start gap-2 items-center">
                        {updatedContent && (
                            <div className="keepBtn h-6 py-[2px] px-2 rounded bg-accent-green text-white font-medium text-[12px] flex flex-row items-center gap-1 justify-start cursor-pointer" onClick={handleKeep}>
                                <img src={check_small} alt="check small"/>
                                Keep
                            </div>
                        )}
                        {updatedContent && (
                            <div className="normalBtn h-6 py-[2px] px-1 flex flex-row text-[12px] font-medium items-center gap-1 cursor-pointer" onClick={() => setShowDiff(!showDiff)}>
                                <span>&hArr;</span>
                                {showDiff ? 'Hide': 'View'} changes
                            </div>
                        )}
                        {resumeSection === "experience" && !validWorkExperiences()? (<></>): (
                            <div className="normalBtn h-6 py-[2px] px-1 flex flex-row text-[12px] font-medium items-center gap-1 cursor-pointer" onClick={handleRegenerate}>
                                <img src={cached} alt="cached"/>
                                {customized? 'Regenerate': 
                                    resumeSection === "summary" && content === "" ? 'Generate' : 
                                    resumeSection === "skills" && (content.length === 0 || (content.length === 1 && content[0] === "")) ? 'Generate Skill List': 'Re-write'
                                }
                            </div>
                        )}
                        {updatedContent && (
                            <div className="normalBtn h-6 py-[2px] px-1 flex flex-row text-[12px] font-medium items-center gap-1 cursor-pointer" onClick={handleRevertChanges}>
                                <img src={undo} alt="undo"/>
                                Revert
                            </div>
                        )}
                        {/* <div className="normalBtn h-6 py-[2px] px-1 flex flex-row text-[12px] font-medium items-center gap-1 cursor-not-allowed">
                            <img src={deleteIcon} alt="delete"/>
                            Delete Section
                        </div> */}
                    </div>
                ) : (
                    // Loading when regenerting
                    <div className="sectionButtons flex flex-row justify-start gap-2 items-center">
                        <div className="loading h-6 py-[2px] px-1 flex items-center">
                            <img className="animate-spin" src={cached} alt="cached"/>
                        </div>
                    </div>
                )
            )}
        </div>
    )
}

export default ResumeSection;