import React, { useState } from "react";

function Search() {
  return (
    <div className="mx-auto py-12 px-20 w-4/5 bg-accent-blue-light rounded-[10px]">
      <form className="bg-white mx-auto rounded-[10px]" role="search">
        <div className="px-6 py-3 flex flex-row justify-between gap-y-1">
          <input
            className="w-full pl-2"
            placeholder="Search for roles, keywords, companies"
            name="srch-term"
            id="srch-term1"
            type="text"
          />
          {/* <div className="border-1 border-black"></div> */}
          <input
            className="w-full pl-2"
            placeholder="Enter location or “remote”"
            name="srch-term"
            id="srch-term2"
            type="text"
          />
          <button
            className="bg-accent-blue text-nowrap py-4 px-8 rounded-lg text-white"
            type="submit"
          >
            Find Jobs
          </button>
        </div>
      </form>
    </div>
  );
}

export default Search;
