import React, { useState } from "react";
import Header from "./Header";
import JobCategories from "./JobCategories";
import manpower_logo from "../../assets/images/manpower_logo.png";
import FindJobs from "./FindJobs";
import Footer from "./Footer";



function Landing() {
  
  return (
    <div className="flex flex-col mx-auto">
      <Header />
      <JobCategories />
      <FindJobs />
      <Footer />
    </div>
  );
}
export default Landing;