import React, { useState } from "react";
import JobCard from "../../components/JobCard/JobCard";
import medline_logo from "src/assets/images/medline-industries-logo.svg";
import sam_logo from "src/assets/images/samuel-logo.svg";
import jd_logo from "src/assets/images/jd-logo.svg";
import gamco_logo from "src/assets/images/gamco_logo.svg";
import archer_logo from "src/assets/images/archer_logo.svg";
import blick_logo from "src/assets/images/blick_logo.svg";

import pagination_img from "src/assets/images/pagination.svg";

function JobsList() {
  const jobs = [
    {
      id: 1,
      companyName: "Medline Industries",
      imageUrl: medline_logo,
      title: "Production Worker",
      description:
        "The Production Worker assembles customer orders from stock and places orders on pallets or shelves, or conveys orders to packing station or shipping department. Also sorts and places materials or items on racks, shelves, or in bins.",
      salary: "$20 - $28 /hr",
      roleType: "Full-Time",
      location: "New York, NY",
    },
    {
      id: 2,
      companyName: "Samuel and Samuel LLT",
      imageUrl: sam_logo,
      title: "Production / Assembly Line Worker",
      description:
        "Industrial bakery is seeking a cross-trainable production line worker. They will be responsible for making bread and placing them into trays. Lifting these 20-30lb trays will be an all day task and will need to be loaded onto racks.",
      salary: "$17/hr",
      roleType: "Full-Time",
      location: "Brooklyn, NY",
    },
    {
      id: 3,
      companyName: "JD Sweid Food",
      imageUrl: jd_logo,
      title: "General Laborer",
      description:
        "Industrial bakery is seeking a cross-trainable production line worker. They will be responsible for making bread and placing them into trays. Lifting these 20-30lb trays will be an all day task and will need to be loaded onto racks.",
      salary: "$680-$720/week",
      roleType: "Part-Time",
      location: "Brooklyn, NY",
    },
    {
      id: 4,
      companyName: "Gamco Corporation",
      imageUrl: gamco_logo,
      title: "Factory Worker",
      description:
        "Follow cutting lists to retrieve materials, cut to size as directed by supervisor.Work in hardware/packaging areas as directed by supervisor.Operate machinery as needed while following safety protocols",
      salary: "$20 - $28 /hr",
      roleType: "Part-Time",
      location: "Brooklyn, NY",
    },
    {
      id: 5,
      companyName: "Archer Daniels Midland",
      imageUrl: archer_logo,
      title: "Production Worker",
      description:
        "The Production Worker will ensure mill has proper wheat mix, wheat has optimal moisture gain, and production schedule is followed.",
      salary: "$20 - $28 /hr",
      roleType: "Part-Time",
      location: "Brooklyn, NY",
    },
    {
      id: 6,
      companyName: "Blick Art Materials",
      imageUrl: blick_logo,
      title: "General Laborer",
      description:
        "Industrial bakery is seeking a cross-trainable production line worker. They will be responsible for making bread and placing them into trays. Lifting these 20-30lb trays will be an all day task and will need to be loaded onto racks.",
      salary: "$680-$720/week",
      roleType: "Part-Time",
      location: "Brooklyn, NY",
    },
  ];

  return (
    <div className="mt-6 w-3/5">
      <p className="text-left font-semibold">234 filtered jobs</p>
      <div className="mt-6 space-y-4">
        {jobs.map((job) => (
          <JobCard key={job.id} job={job} />
        ))}
      </div>
      <div className="flex justify-center mt-12">
        <img src={pagination_img}></img>
      </div>
    </div>
  );
}

export default JobsList;
