import React, { useState } from "react";
import SalaryChart from "./SalaryChart";

type TabName = "Yearly" | "Monthly" | "Weekly" | "Hourly" | "TableView";

const tabs: TabName[] = ["Yearly", "Monthly", "Weekly", "Hourly", "TableView"];

const BaseSalary: React.FC = () => {
  const [activeTab, setActiveTab] = useState<TabName>("Yearly");

  return (
    <>
      <div className="bg-white shadow-md rounded-lg p-6">
        {/* Tabs */}
        <div className="border-b border-gray-200">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
            {tabs.map((tab) => (
              <li key={tab} className="mr-2">
                <button
                  className={`inline-block p-4 rounded-t-lg hover:text-gray-600 hover:border-gray-300 ${activeTab === tab
                    ? "text-blue-600 border-b-2 border-blue-600"
                    : ""
                    }`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              </li>
            ))}
          </ul>
        </div>
        {/* Chart and Salary Info Container */}
        <div className="flex items-center justify-between pt-4">
          <div className="flex flex-col space-y-2">
            {" "}
            {/* Adjust space as needed */}
            <p className="text-lg">Average Base Pay</p>
            <p
              className="text-4xl font-bold"
              style={{
                color: "var(--Neutral-900, #141414)",
                lineHeight: "48px",
              }}
            >
              $35,075
              <span className="text-sm font-normal">/year</span>
            </p>
            <p className="pt-12 text-left text-sm">New York, NY $17.45 /hr</p>
            <p className="text-left text-sm">National $15.00 / hr</p>
          </div>
          <div className="flex-grow" style={{ maxWidth: "531px" }}>
            {" "}
            {/* Set exact width */}
            <SalaryChart />
          </div>
        </div>
      </div>

      {/* NEW SECTION  */}
      <div className="mt-4 flex justify-between">
        {/* Card 1 */}
        <div
          className="bg-white shadow-md rounded-lg p-6 text-left"
          style={{ width: "17rem", height: "15rem" }}
        >
          {/* Content of Card 1 */}
          <h4 className="font-semibold mb-3">Boost Your Skills</h4>
          <hr></hr>
          <div className="flex flex-wrap gap-2 mt-5">
            <div><p className="text-sm bg-neutral-100 py-2 px-4 rounded">Computer Literacy</p></div>
            <div><p className="text-sm bg-neutral-100 py-2 px-4 rounded">Forklift</p></div>
            <div><p className="text-sm bg-neutral-100 py-2 px-4 rounded">Cashier</p></div>
            <div><p className="text-sm bg-neutral-100 py-2 px-4 rounded">Assembly Line</p></div>
          </div>
        </div>
        {/* Card 2 (Wider Middle Card) */}
        <div
          className="bg-white shadow-md rounded-lg p-6 mx-2 text-left"
          style={{ width: "calc(17rem + 2rem)", height: "15rem" }}
        >
          {/* Content of Card 2 */}
          <h4 className="font-semibold mb-3">Salary for Related Jobs</h4>
          <hr></hr>
          <div className="flex flex-col gap-2 mt-5">
            <div className="flex flex-row justify-between">
              <p className="text-accent-blue">Production Technician</p>
              <p className="text-accent-green">$44,416</p>
            </div>
            <div className="flex flex-row justify-between">
              <p className="text-accent-blue">Filling Machine Operator</p>
              <p className="text-accent-green">$45,568</p>
            </div>
            <div className="flex flex-row justify-between">
              <p className="text-accent-blue">Packaging Machine Operator</p>
              <p className="text-accent-green">$42,123</p>
            </div>
            <div className="flex flex-row justify-between">
              <p className="text-accent-blue">Mixing Machine Operator</p>
              <p className="text-accent-green">$45,056</p>
            </div>
          </div>
        </div>
        {/* Card 3 */}
        <div
          className="bg-white shadow-md rounded-lg p-6 text-left"
          style={{ width: "17rem", height: "15rem" }}
        >
          {/* Content of Card 3 */}
          <h4 className="font-semibold mb-3">Your Career Path</h4>
          <hr></hr>
          <div className="flex flex-wrap gap-2 mt-5">
            <div><p className="text-sm bg-neutral-100 py-2 px-4 rounded">Heat Treating Technician</p></div>
            <div><p className="text-sm bg-neutral-100 py-2 px-4 rounded">Hazardous Materials Worker</p></div>
            <div><p className="text-sm bg-neutral-100 py-2 px-4 rounded">Food / Agricultural Technician</p></div>
          </div>

        </div>
      </div>
    </>
  );
};

export default BaseSalary;
