import React, { useEffect, useState, useRef } from "react";
import sendMessages, {
  Action,
} from "../../services/WebsocketManager.ts";
import { useSession } from "src/context/SessionProvider.tsx";
import { ScaleLoader } from "react-spinners";
import { QuestionCard } from "./Interview.tsx"; 
import "../../components/SideNav/SideNav.css";
import { space } from "postcss/lib/list";

type AnswerFormProps = {
  questionId: number;
  questionCards: QuestionCard[];
  setActiveQuestionId: (questionId: number | null) => void;
  setQuestionCards: React.Dispatch<React.SetStateAction<QuestionCard[]>>;
  jobTitle: string;
  jobDescription: string;
  cv: any;
};

export default function AnswerForm({
  questionId,
  questionCards,
  setActiveQuestionId,
  setQuestionCards,
  jobTitle,
  jobDescription,
  cv
}: AnswerFormProps) {
  const [currentCard, setCurrentCard] = useState<QuestionCard>(questionCards[questionId - 1])

  const [answer, setAnswer] = useState(currentCard.answer);
  const [checkingAnswer, setCheckingAnswer] = useState<boolean>(false);
  const hasComponentBeenRendered = useRef(false);

  const { sessionID, wsMessage } = useSession();

  const handleAnswerChange = (newAnswer: string) => {
    setAnswer(newAnswer); // Update local state immediately
  };

  const handleSubmitAnswer = () => {
    setCheckingAnswer(true);
    const messageData = {
      action: 'sendMessage',
      session_id: sessionID,
      job_title: jobTitle,
      job_description: jobDescription,
      cv: JSON.stringify(cv).replaceAll("\"", "'"),
      interview_question: currentCard.question,
      interview_answer: answer
    };
    sendMessages(Action.RESUME, messageData);
  };

  const handleResetAnswer = () => {
    const updatedCard = {
      ...currentCard,
      answer: "", 
      state: "unanswered", 
      feedback: "",
      example_answer: "",
      reasoning: ""
    }
    let updatedCards = [...questionCards];
    updatedCards[questionId - 1] = updatedCard;
    setQuestionCards(updatedCards);
    setCurrentCard(updatedCard);
  }

  useEffect(() => {
    if (hasComponentBeenRendered.current && wsMessage?.generated_response_to_answer?.feedback) {
        const updatedCard = {
          ...currentCard,
          answer: answer, 
          state: "answered", 
          feedback: wsMessage.generated_response_to_answer.feedback,
          example_answer: wsMessage.generated_response_to_answer.example_answer,
          reasoning: wsMessage.generated_response_to_answer.reasoning
        };

        let updatedCards = [...questionCards];
        updatedCards[questionId - 1] = updatedCard;
        setQuestionCards(updatedCards);
        setCurrentCard(updatedCard);
        setCheckingAnswer(false);
    }
    hasComponentBeenRendered.current = true;
  }, [wsMessage])

  return (
    <div className="flex flex-col items-center justify-center sideNavContainer">
      <h1 className="text-neutral-900 text-center text-3xl font-semibold leading-[46.8px] mb-12">
        Job Interview Preparation
      </h1>
      <div className="w-[46.875rem] h-fit rounded border border-neutral-300 bg-white shadow-[6px_6px_54px_rgba(0,0,0,0.05)] px-8 py-6 flex items-center justify-start mb-4">
          <div>
            <label
              htmlFor="question"
              className="text-left text-lg font-semibold text-neutral-900 block mb-4"
            >
              Question {questionId}:
            </label>
            <div className="w-full rounded-md text-left">{currentCard.question}</div>
          </div>
        </div>
        { currentCard.state === "answered" ? (
          <div className="w-[46.875rem] h-fit rounded border border-neutral-300 bg-white shadow-[6px_6px_54px_rgba(0,0,0,0.05)] px-8 py-6 flex flex-col items-stretch">
            <div className="mb-8 pt-2">
              <label
                htmlFor="answer"
                className="text-left text-lg font-semibold text-neutral-900 block mb-4"
              >
                Your answer:
              </label>
              <div className="w-full h-fit text-left">{answer}</div>
            </div>
            <div className="mb-8">
              <div
                className="text-left text-lg font-semibold text-neutral-900 block mb-4"
              >
                Feedback:
              </div>
              <div className="w-full rounded-md text-left">{currentCard.feedback}</div>
            </div>
            <div className="mb-6">
              <div
                className="text-left text-lg font-semibold text-neutral-900 block mb-4"
              >
                Example answer:
              </div>
              <div className="w-full rounded-md text-left">{currentCard.example_answer}</div>
            </div>
            <div className="mb-8">
              <div
                className="text-left text-md font-semibold text-neutral-900 block mb-4"
              >
                Reasoning:
              </div>
              <div className="w-full rounded-md text-left">{currentCard.reasoning}</div>
            </div>
            <div className="flex items-start mt-2">
              <div
                onClick={handleResetAnswer}
                className="w-[200px] h-[40px] rounded-md bg-[#3B8160] hover:bg-green-600 text-white flex items-center justify-center cursor-pointer"
              >
                Answer again
              </div>
              <div
                onClick={() => setActiveQuestionId(null)}
                className="ml-2 w-[200px] h-[40px] rounded-md bg-white hover:bg-neutral-100 text-[#3B8160] flex items-center justify-center cursor-pointer border-[1px] border-[#3B8160]"
              >
                Back to questions
              </div>
            </div>
          </div>
        ) : (
          <div className="w-[46.875rem] h-fit rounded border border-neutral-300 bg-white shadow-[6px_6px_54px_rgba(0,0,0,0.05)] px-8 py-6 flex flex-col items-stretch">
            <div className="mb-6 pt-2">
              <label
                htmlFor="answer"
                className="text-left text-lg font-semibold text-neutral-900 block mb-4"
              >
                Your answer:
              </label>
              <textarea
                id="answer"
                value={answer}
                onChange={(e) => handleAnswerChange(e.target.value)}
                rows={4}
                className="w-full min-h-28 p-2 border border-neutral-300 rounded-md"
              ></textarea>
            </div>
              {checkingAnswer ? (<ScaleLoader color="#3B8160"/>) : (
                <div className="flex items-start mt-2">
                  <div
                    onClick={answer && answer !== ""? handleSubmitAnswer: () => {return;}}
                    className={
                      `w-[200px] h-[40px] rounded-md text-white flex items-center justify-center
                      ${!answer || answer === ""? 'bg-neutral-700 cursor-not-allowed': 'cursor-pointer hover:bg-green-600 bg-[#3B8160]'}`
                    }
                  >
                    Submit answer
                  </div>
                  <div
                    onClick={() => setActiveQuestionId(null)}
                    className="ml-2 w-[200px] h-[40px] rounded-md bg-white hover:bg-neutral-100 text-[#3B8160] flex items-center justify-center cursor-pointer border-[1px] border-[#3B8160]"
                  >
                    Back to questions
                  </div>
                </div>
              )}
          </div> 
        )}
      </div>
  );
}
