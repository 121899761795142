// Import the createSlice function from Redux Toolkit
import { createSlice } from "@reduxjs/toolkit";

// Define a new slice of the Redux store
export const resumeSlice = createSlice({
    // The name of the slice
    name: "resume",
    // The initial state of the slice
    initialState: {
        summary: "Qualified warehouse associate with 14 years of experience in distribution processes, inventory control, and heavy machinery operations. Utilize advanced knowledge of OSHA regulations to maintain consistently safe working conditions within warehouses of all sizes. Diligent worker with a proven record of meeting strict deadlines without compromising product quality.",
        skills: ["Inventory control and quality assurance", "Shipping and receiving processes", "OSHA regulations and safety laws"],
        experience: [
            { 
                title: "Warehouse Operations Associate", 
                timeline: "2020 – present",
                location: "Shulman Distributors, Chicago, IL",
                details: ["Prepare merchandise for shipment with proper boxing, padding, sealing, and supporting methods", "Monitor inventory levels, report low supplies to management, and fill out order requests on a weekly basis to avoid disruptions to the distribution process", "Recommend updated safety protocols to reduce warehouse injuries by 25%"]
            },
            { 
                title: "Warehouse Operations Associate", 
                timeline: "2017 - 2020",
                location: "Foxman Goods & Supplies, Chicago, IL",
                details: ["Labelled all incoming packages and materials and stocked items on proper shelves or storage spaces within the warehouse", "Upheld a clean and safe working environment inside the warehouse at all times according to safety laws and company standards", "Inspected merchandise for damages, performed corrective measures, and compiled reports on discrepancies for management"]
            },
        ],
        education: [
            {
                name: "Associates Degree, Liberal Arts & Sciences",
                location: "Miami Dade College, Miami, Florida"
            },
            {
                name: "OSHA Certified Forklift Operator ",
                location: "LIFTOFF, Chicago, Illinois"
            },
        ],
        name: "Jordan Taylor",
        location: "Chicago, Illinois, USA",
        phone: "555-555-4376",
        email: "jordan.taylor@gmail.com"
    },
    // The reducers for this slice
    reducers: {
        // Each reducer takes the current state and an action, and returns the new state
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setSkills: (state, action) => {
            state.skills = action.payload;
        },
        setExperience: (state, action) => {
            state.experience = action.payload;
        },
        setEducation: (state, action) => {
            state.education = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setPhone: (state, action) => {
            state.phone = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const { setSummary, setSkills, setExperience, setEducation, setName, setLocation, setPhone, setEmail } = resumeSlice.actions;

// Selectors allow you to access specific parts of the state
export const selectSummary = (state: any) => state.resume.summary;
export const selectSkills = (state: any) => state.resume.skills;
export const selectExperience = (state: any) => state.resume.experience;
export const selectEducation = (state: any) => state.resume.education;
export const selectName = (state: any) => state.resume.name;
export const selectLocation = (state: any) => state.resume.location;
export const selectPhone = (state: any) => state.resume.phone;
export const selectEmail = (state: any) => state.resume.email;
export const selectWholeResume = (state: any) => {
    return {
        "name": state.resume.name,
        "location": state.resume.location,
        "phone": state.resume.phone,
        "email": state.resume.phone,
        "summary": state.resume.summary,
        "skills": state.resume.skills,
        "experience": state.resume.experience,
        "education": state.resume.education
    }
};

// Export the reducer for this slice, so it can be included in the store
export default resumeSlice.reducer;