import React, { useState } from "react";
import Header from "./Header";
import FiltersMenu from "./FiltersMenu";
import JobsList from "./JobsList";
import ApplicationTracker from "./ApplicationTracker";



function Jobs() {
    const [currentView, setCurrentView] = useState(1);

    const changeView = (index: number) => {
        setCurrentView(index)
    }

    return (
        <div>
            <div className="flex flex-col mx-auto">
                <Header changeView={changeView}/>
            </div>
            { currentView == 1 &&
                <div className="flex flex-wrap mx-auto max-w-full p-14 bg-[#F9F8F8]">
                    <FiltersMenu />
                    <JobsList />
                </div>
            }
            {
                currentView == 3 &&
                <div className="flex flex-wrap mx-auto max-w-full bg-[#F9F8F8]">
                    <ApplicationTracker />
                </div>
            }
        </div>
    );
}
export default Jobs;