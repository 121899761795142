import React, { useState } from "react";
import socials from "../../assets/images/socials.svg";


function Footer() {

  return (
    <div className="max-w-full flex flex-col center py-16 px-80 bg-gray-100 text-left items-start gap-20">
      <div className="h-px my-8 bg-black border-0"></div>
      <div className="flex flex-row gap-20 text-nowrap">
        <div className="flex flex-col text-left gap-4">
          <p className="font-semibold text-lg">Company</p>
          <p className="font-normal text-neutral-700 text-base">About us</p>
          <p className="font-normal text-neutral-700 text-base">Contact us</p>
          <p className="font-normal text-neutral-700 text-base">Careers</p>
          <p className="font-normal text-neutral-700 text-base">Our partners</p>
          <p className="font-normal text-neutral-700 text-base">FAQs</p>
          <p className="font-normal text-neutral-700 text-base">Press</p>
        </div>
        <div className="flex flex-col text-left gap-4">
          <p className="font-semibold text-lg">Job Seekers</p>
          <p className="font-normal text-neutral-700 text-base">Search Jobs</p>
          <p className="font-normal text-neutral-700 text-base">Browse Jobs</p>
          <p className="font-normal text-neutral-700 text-base">Search Salaries</p>
          <p className="font-normal text-neutral-700 text-base">Create Free Account</p>
          <p className="font-normal text-neutral-700 text-base">Job Seeker Chat</p>
          <p className="font-normal text-neutral-700 text-base">Privacy and Security</p>
        </div>
        <div className="flex flex-col text-left gap-4">
          <p className="font-semibold text-lg">Job by Industry</p>
          <p className="font-normal text-neutral-700 text-base">Aerospace</p>
          <p className="font-normal text-neutral-700 text-base">Consultancy</p>
          <p className="font-normal text-neutral-700 text-base">Defence and Military</p>
          <p className="font-normal text-neutral-700 text-base">Hospitality and Catering</p>
          <p className="font-normal text-neutral-700 text-base">Marketing</p>
          <p className="font-normal text-neutral-700 text-base">Recruitment Consultancy</p>
        </div>
        <div className="flex flex-col text-left gap-4">
          <p className="font-semibold text-lg">Jobs by Location</p>
          <p className="font-normal text-neutral-700 text-base">Anaheim</p>
          <p className="font-normal text-neutral-700 text-base">Annapolis</p>
          <p className="font-normal text-neutral-700 text-base">Chicago</p>
          <p className="font-normal text-neutral-700 text-base">Milwaukee</p>
          <p className="font-normal text-neutral-700 text-base">New York</p>
          <p className="font-normal text-neutral-700 text-base">Phoenix</p>
        </div>
      </div>
      <img src={socials} />
      <p className="font-medium">ManpowerGroup © 2024 All Rights Reserved Worldwide</p>
    </div>
  );
}

export default Footer;