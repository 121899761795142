import React, { useState } from "react";
import Search from "./Search";
import JobCategory from "../../components/JobCategory";
import explore_arrow from "../../assets/images/ic_chevron_right.svg";



function JobCategories() {

  return (
    <div className="max-w-full p-16 bg-accent-blue-light text-left">
      <div className="flex flex-row justify-between">
        <div className="flex flex-col gap-4 mb-9">
          <h1 className="text-neutral-900 font-bold text-4xl">Jobs by category</h1>
          <h4 className="font-normal ">Your perfect fit is out there. Let's find it together. Discover hundreds of exciting jobs.</h4>
        </div>
        <div className="flex flex-row justify-between content-center">
          <p className="text-accent-blue">Explore all categories</p>
          <img className="w-4" src={explore_arrow}></img>
        </div>
      </div>
      <div className="grid grid-cols-3 grid-rows-2 gap-8">
        <JobCategory />
        <JobCategory />
        <JobCategory />
        <JobCategory />
        <JobCategory />
        <JobCategory />
      </div>
    </div>
  );
}
export default JobCategories;