import React, { useEffect, useState } from "react";
import AnswerForm from "./AnswerForm";
import SideNav from "src/components/SideNav/SideNav";
import {
    selectRecommendedJobs,
    selectSelectedJobId,
    Job
} from "../../store/jobs.slice";
import {
  selectWholeResume
} from "../../store/resume.slice";
import { useSelector } from "react-redux";
import sendMessages, {
  Action,
} from "../../services/WebsocketManager.ts";
import { useSession } from "src/context/SessionProvider.tsx";
import { ScaleLoader } from "react-spinners";

export type QuestionCard = {
  id: number;
  question: string;
  answer: string;
  state: string;
  feedback?: string;
  example_answer?: string;
  reasoning?: string;
};

function Interview() {
  const cv: any = useSelector(selectWholeResume);
  const jobId: number = useSelector(selectSelectedJobId);
  const recommendedJobs: Job[] = useSelector(selectRecommendedJobs);
  const selectedJobDetails = recommendedJobs.filter((job: Job) => job.id === jobId)[0]

  const { sessionID, wsMessage } = useSession();

  const [questionCards, setQuestionCards] = useState<QuestionCard[]>([]);
  const [activeQuestionId, setActiveQuestionId] = useState<number | null>(null);
  const [jobTitle, setJobTitle] = useState(selectedJobDetails.title);
  const [jobDescription, setJobDescription] = useState(selectedJobDetails.about_this_role);
  const [viewMode, setViewMode] = useState("none");

  const [generatingQuestions, setGeneratingQuestions] = useState<boolean>(false);

  const handleAnswerClick = (questionId: number) => {
    setActiveQuestionId(questionId);
    setViewMode("answer");
  };

  const handleGenerateQuestions = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setGeneratingQuestions(true)
    // Construct request object
    const messageData = {
      action: 'sendMessage',
      session_id: sessionID,
      job_title: jobTitle,
      job_description: jobDescription || "No description",
      cv: JSON.stringify(cv).replaceAll("\"", "'")
    };
    sendMessages(Action.RESUME, messageData)
  };

  useEffect(() => {
    if(wsMessage?.generated_questions?.length > 0 ) {
      let tempQuestions: QuestionCard[] = [];
      wsMessage.generated_questions.forEach((question: string, index: number) => {
        tempQuestions.push({
          id: index + 1,
          question: question,
          answer: "",
          state: "unanswered"
        })
      });
      setQuestionCards(tempQuestions)
      setGeneratingQuestions(false);
    }
  }, [wsMessage])

  return (
    viewMode === "answer" && activeQuestionId ? 
      <AnswerForm
        jobTitle={jobTitle}
        jobDescription={jobDescription}
        cv={cv}
        questionId={activeQuestionId}
        questionCards={questionCards}
        setActiveQuestionId={setActiveQuestionId}
        setQuestionCards={setQuestionCards}
      />
    :
      <div className="pageContainer w-full h-fit flex flex-row items-stretch">
        <SideNav />
        <div className="flex flex-col items-center justify-start flex-1 bg-neutral-100 pt-16">
          <h1 className="text-neutral-900 text-center text-3xl font-semibold leading-[46.8px] mb-4">
            Job Interview Preparation
          </h1>
          <p className="text-neutral-700 text-center text-base font-medium leading-[24px] max-w-[80%]">
            Get ready for your job interview with AI-generated questions!
          </p>
          <p className="text-neutral-700 text-center text-base font-medium leading-[24px] max-w-[80%] mb-12">
            You can create potential interview questions tailored to the position
            you're applying for.
          </p>
          <div className="w-[46.875rem] min-h-[27.0rem] rounded-[16px] border border-neutral-300 bg-white shadow-[6px_6px_54px_rgba(0,0,0,0.05)] p-10 flex flex-col items-center">
            <form onSubmit={handleGenerateQuestions} className="w-full">
              <div className="mb-6">
                <label
                  htmlFor="jobTitle"
                  className="text-left text-lg font-semibold text-neutral-900 block mb-4"
                >
                  Job title
                </label>
                <input
                  required
                  type="text"
                  id="jobTitle"
                  value={jobTitle}
                  onChange={(e) => setJobTitle(e.target.value)}
                  placeholder="e.g Warehouse Operations Administrator"
                  className="w-full p-2 border border-neutral-300 rounded-md"
                  disabled={questionCards.length > 0}
                />
              </div>
              <div className="mb-6 pt-2">
                <label
                  htmlFor="jobDescription"
                  className="text-left text-lg font-semibold text-neutral-900 block mb-4"
                >
                  Job description (Optional)
                </label>
                <textarea
                  id="jobDescription"
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  rows={4}
                  placeholder="Paste the job description here..."
                  className="w-full min-h-28 p-2 border border-neutral-300 rounded-md"
                  disabled={questionCards.length > 0}
                ></textarea>
              </div>
              {questionCards.length === 0 ? 
                <div className="flex items-start mt-2">
                  {generatingQuestions? (<ScaleLoader color="#3B8160"/>) : (
                    <button
                      type="submit"
                      className="w-[280px] h-[40px] rounded-lg bg-[#3B8160] hover:bg-green-600 text-white"
                    >
                      Generate interview questions
                    </button>
                  )}
                </div>
                :
                generatingQuestions? (<ScaleLoader color="#3B8160"/>) : (
                  <div className="flex items-start mt-2 gap-4">
                    <div
                      className="w-fit h-10 px-6 rounded-lg text-sm bg-[#3B8160] hover:bg-green-600 text-white cursor-pointer flex items-center justify-center"
                      onClick={() => {setQuestionCards([])}}
                    >
                      Try a different job
                    </div>
                    <button
                      type="submit"
                      className="w-fit h-[40px] rounded-md bg-white hover:bg-neutral-100 text-[#3B8160] flex items-center justify-center cursor-pointer border-[1px] border-[#3B8160] px-6"
                    >
                      Re-generate interview questions
                    </button>
                  </div>
                )
              }
            </form>
          </div>
          {questionCards.length > 0 && (
            <>
              <p className="text-neutral-700 text-center text-base font-base leading-[24px] max-w-[80%] mt-12">
                Here are {questionCards.length} interview questions for{" "}
              </p>
              <p className="text-neutral-900 text-center text-lg font-semibold leading-[46.8px] mt-[-5px] mb-6">
                {jobTitle}
              </p>
              <div className="flex flex-col items-center justify-center w-full max-w-4xl space-y-4 pb-12">
                {questionCards.map((card, idx) => (
                  <div
                    key={card.id}
                    className="flex flex-col p-8 border border-neutral-300 shadow-sm rounded-lg bg-white w-[46.875rem] h-fit"
                  >
                    <div>
                      <p className="text-base font-semibold text-black text-left">
                        Question {idx + 1}:
                      </p>
                      <div className="text-sm text-gray-500 mt-2.5 text-left">
                        "{card.question}"
                      </div>
                    </div>
                    <div className="mt-10">
                      {" "}
                      {card.state === "answered" ? (
                        <div className="text-accent-green cursor-pointer w-full flex justify-start font-semibold">
                          <button onClick={() => handleAnswerClick(card.id)}>
                            {" "}
                            View your answer and feedback &rarr;
                          </button>
                        </div>
                      ) : (
                        <button
                          className="flex justify-center items-center gap-2 px-6 py-2.5 bg-[#3B8160] rounded-lg hover:bg-green-600 text-white"
                          onClick={() => handleAnswerClick(card.id)}
                        >
                          Answer
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
  );
}

export default Interview;
