import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { SessionProvider } from "src/context/SessionProvider";
import Navbar from "../components/Navbar/Navbar";
import ChatWindow from "src/components/ChatWindow/ChatWindow";

function Outlay() {
  const [chatOpen, setChatOpen] = useState(false);

  return (
    <>
      <SessionProvider>
        <Navbar setChatOpen={setChatOpen} />
        <Outlet />
        {/* Chat Window */}
        <ChatWindow
          chatWindowOpen={chatOpen}
          toggleChatWindow={() => setChatOpen(!chatOpen)}
        />
      </SessionProvider>
    </>
  );
}
export default Outlay;
