import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    x: {
      labels: ["$25,540", "", "", "", "$35,075", "", "", "", "$42,000"],
      grid: {
        display: false,
        drawBorder: false,
      },
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0,
        font: {
          size: 16, // Equivalent to Tailwind's text-base
          weight: 600, // Equivalent to Tailwind's semibold
        },
      },
    },
    y: {
      display: false,
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || "";
          if (label) {
            label += ": ";
          }
          if (context.parsed.y !== null) {
            label += context.parsed.y + "%"; // Updated to show percentage
          }
          return label;
        },
        title: function (context: any) {
          return context[0].label;
        },
      },
    },
  },
  indexAxis: "x" as const,
  elements: {
    bar: {
      backgroundColor: "#DDE2E5",
      barThickness: 45 / 16,
      borderRadius: 4,
    },
  },
};

// Updating chartData to specify the backgroundColor for each bar
const chartData = {
  labels: Array(9).fill(""),
  datasets: [
    {
      label: "Percentage",
      data: [7, 7, 10, 25, 25, 11, 6, 5, 4],
      borderWidth: 1,
      // Use a function to dynamically set bar color; highlight the 5th bar
      backgroundColor: function (context: any) {
        var index = context.dataIndex;
        return index === 4 ? "#72B38D" : "#DDE2E5";
      },
    },
  ],
};

const SalaryChart: React.FC = () => (
  <div style={{ width: "531px", height: "112px" }}>
    <Bar data={chartData} options={options} />
  </div>
);

export default SalaryChart;
