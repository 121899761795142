import React, { useState } from "react";
import arrow from "../assets/images/arrow.svg";



function JobCategory() {

  return (
    <div className=" flex flex-row justify-between bg-white text-left pt-8 px-8 pb-9 rounded-[10px]">
      <div className="flex flex-col gap-4">
        <h3 className="text-neutral-900 font-semibold">Business & Management</h3>
        <h4 className="text-neutral-700 font-regular">43 Jobs</h4>
      </div>
      <img src={arrow}></img>
    </div>
  );
}

export default JobCategory;