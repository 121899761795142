import React from "react";
import right_arrow from "../../assets/images/ic_chevron_right.svg";

const JobCard = ({ job }: any) => {
  return (
    <div className="w-[54.6875rem] bg-white shadow-md rounded-lg overflow-hidden flex p-6 border border-gray-200">
      {/* Image container */}
      <div className="w-24 h-24 mr-6 flex-shrink-0">
        {" "}
        {/* Adjust width and height as needed */}
        <img
          src={job.imageUrl} // Replace with your image path
          alt={`${job.companyName} Logo`}
          className="w-16 h-16 object-cover rounded-lg"
        />
      </div>

      {/* Main content */}
      <div className="flex-grow">
        <div className="flex justify-between items-start text-left">
          <div>
            <h3 className="text-sm font-light">{job.companyName}</h3>
            <h3 className="text-2xl font-semibold">{job.title}</h3>
            <p className="text-gray-700">{job.description}</p>
          </div>
          <div className="text-sm text-gray-500">
            {job.postedDate} {/* Example: '2 days ago' */}
          </div>
        </div>

        {/* Additional details like salary, type, location */}
        <div className="mt-4 flex items-center justify-between">
          <div className="mt-4 flex gap-4 items-center">
            <span className="font-semibold text-neutral-900 bg-[#F5F5F5] py-2 px-4 rounded">{job.salary}</span>
            <span className="font-semibold text-neutral-900 bg-[#F5F5F5] py-2 px-4 rounded">{job.roleType}</span>
            <span className="font-semibold text-neutral-900 bg-[#F5F5F5] py-2 px-4 rounded">{job.location}</span>
          </div>
          <a href={job.detailsUrl} className="text-indigo-600 hover:underline flex flex-row items-center">
            View details
            <img className="w-4" src={right_arrow}></img>
          </a>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
