import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedJobId } from "../../store/jobs.slice.ts";

interface JobDescription {
  id: string;
  title: string;
  company: string;
  location: string;
  salary_range: string;
  about_us: string;
  about_this_role: string;
  responsibilities: string[];
  company_name?: string;
}

interface ChatDescriptionCardProps {
  job: JobDescription;
  toggleChatWindow: () => void;
  setLoadingChatResponse: any;
  packageAndSendMessages: any;
}

const ChatDescriptionCard: React.FC<ChatDescriptionCardProps> = ({
  job,
  toggleChatWindow,
  setLoadingChatResponse,
  packageAndSendMessages
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [hideButtons, setHideButtons] = useState<boolean>(false);

  // Function to handle clicking on the Apply Now button
  const handleApplyNow = () => {
    toggleChatWindow(); // Close chat window
    dispatch(setSelectedJobId(job.id)); // Update selected job id
    navigate("/resume"); // Go to resume builder
  };

  return (
    <div className="max-w-lg text-left mx-auto bg-white p-6 rounded-lg">
      <h2 className="text-2xl font-bold mb-2 text-black">{job.title}</h2>

      <div className="text-gray-800">
        <div className="mb-4">
          <span className="font-medium">Company:</span> {job.company || job.company_name}
        </div>
        <div className="mb-4">
          <span className="font-medium">Location:</span> {job.location}
        </div>
        <div className="mb-4">
          <span className="font-medium">Salary Range:</span> {job.salary_range}
        </div>
      </div>

      <div className="text-gray-600 text-base mb-4">
        <h3 className="font-medium text-base text-black">About us:</h3>
        <p>{job.about_us}</p>
      </div>

      <div className="text-gray-600 mb-4">
        <h3 className="font-medium text-base text-black">About this role:</h3>
        <p>{job.about_this_role}</p>
      </div>

      <div className="text-gray-600">
        <h3 className="font-medium text-base text-black">Responsibilities:</h3>
        <ul className="list-disc pl-5">
          {job.responsibilities.map((responsibility, index) => (
            <li key={index}>{responsibility}</li>
          ))}
        </ul>
      </div>
      {!hideButtons && 
        <div className="jobCardBtns flex flex-col justify-evenly items-stretch gap-4 mt-8">
            <div className="option1 w-full h-12 rounded-lg border flex items-center justify-center text-center bg-accent-blue-light font-medium text-base text-neutral-900 cursor-pointer hover:border-accent-blue" onClick={handleApplyNow}>Tailor my resume to this job</div>
            <div className="option2 w-full h-12 rounded-lg border flex items-center justify-center text-center bg-accent-blue-light font-medium text-base text-neutral-900 cursor-pointer hover:border-accent-blue" onClick={() => {setLoadingChatResponse(true); packageAndSendMessages("Courses and qualifications for this job"); setHideButtons(true);}}>Courses and Qualifications</div>
            <div className="option3 w-full h-12 rounded-lg border flex items-center justify-center text-center bg-accent-blue-light font-medium text-base text-neutral-900 cursor-pointer hover:border-accent-blue" onClick={() => setHideButtons(true)}>Keep exploring more jobs</div>
        </div>
      }
    </div>
  );
};

export default ChatDescriptionCard;
