import React from "react";
import bl_logo from "src/assets/images/b&l_logo.svg";
import willcox_logo from "src/assets/images/willcox_logo.svg";
import levain_bakery_logo from "src/assets/images/levain_bakery_logo.svg";
import pandg_logo from "src/assets/images/p&g_logo.svg";
const companies = [
  {
    id: 1,
    companyName: "Willcox Matthews",
    imageUrl: willcox_logo,
    location: "Yonkers, NY, USA",
    jobs_number: "32",
    employees_number: "300+",
    isFollowing: false,
  },
  {
    id: 2,
    companyName: "Procter and Gamble",
    imageUrl: pandg_logo,
    location: "New York, NY, USA",
    jobs_number: "124",
    employees_number: "107K",
    isFollowing: true,
  },
  {
    id: 3,
    companyName: "Bausch + Lomb",
    imageUrl: bl_logo,
    location: "Queens, NY, USA",
    jobs_number: "19",
    employees_number: "150",
    isFollowing: false,
  },
  {
    id: 4,
    companyName: "Levain Bakery",
    imageUrl: levain_bakery_logo,
    location: "Greenlawn, NY, USA",
    jobs_number: "11",
    employees_number: "95",
    isFollowing: false,
  },
];

export const RecommendedCompanies: React.FC = () => {
  // Component code
  return (
    <div className="mt-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {companies.map((company) => (
        <div
          key={company.id}
          className="bg-white shadow-md rounded-lg p-4 flex flex-col items-start"
        >
          <div className="flex items-center w-full mb-4">
            <img
              src={company.imageUrl}
              alt={`${company.companyName} Logo`}
              className="w-12 h-12 object-cover rounded-lg"
            />
            <div className="ml-4">
              <h3 className="text-lg text-left font-semibold">
                {company.companyName}
              </h3>
              <p className="text-sm text-left">{company.location}</p>
            </div>
          </div>
          <hr className="my-2 border-t" />
          <div className="flex justify-between items-center w-full">
            <div className="text-center">
              <span className="text-lg font-bold">{company.jobs_number}</span>
              <p className="text-sm">Jobs</p>
            </div>
            <div className="text-center">
              <span className="text-lg font-bold">
                {company.employees_number}
              </span>
              <p className="text-sm">Employees</p>
            </div>
            <button
              className={`py-2 px-4 rounded-full text-sm font-semibold border ${
                company.isFollowing ? "bg-green-100 text-green-800" : "bg-white"
              }`}
            >
              {company.isFollowing ? "Following" : "Follow"}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};
