import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { socket } from "src/services/WebsocketManager";

function generateSessionID() {
  let sessionID = sessionStorage.getItem("sessionID");
  if (!sessionID) {
    const now = new Date();
    sessionID = now.toISOString().replace(/\D/g, "");
    sessionStorage.setItem("sessionID", sessionID);
  }
  return sessionID;
}

// Define the type for your context state
type SessionContextType = {
  sessionID: string;
  setSessionID: (id: string) => void;
  wsMessage: any;
};

// Provide a default value matching the type
const defaultSessionContextValue: SessionContextType = {
  sessionID: "",
  setSessionID: () => {}, // Empty function as a placeholder
  wsMessage: null,
};

// Create the context with the default value
const SessionContext = createContext<SessionContextType>(
  defaultSessionContextValue
);

// Custom hook for using session context
export function useSession() {
  return useContext(SessionContext);
}

// Props type for SessionProvider
type SessionProviderProps = {
  children: ReactNode;
};

// Context provider component
export const SessionProvider: React.FC<SessionProviderProps> = ({
  children,
}) => {
  const [sessionID, setSessionID] = useState(generateSessionID()); // To hold session ID
  const [wsMessage, setWSMessage] = useState(null); // To hold last message received through web socket

  useEffect(() => {
    sessionStorage.setItem("sessionID", sessionID);
  }, [sessionID]);

  // Use effect to handle receiving messages from websocket
  useEffect(() => {
    socket.onmessage = (event: { data: any }) => {
        console.log("Received message", event.data);
        try {
            const data = JSON.parse(event.data);
            if (data.statusCode === 200) {
                setWSMessage(data); // Update context for components
            } else {
            console.log("Received error from server:", data);
            }
        } catch (error) {
            console.log("Error parsing message from ws:", error);
        }
    };
  }, [])

  return (
    <SessionContext.Provider value={{ sessionID, setSessionID, wsMessage }}>
      {children}
    </SessionContext.Provider>
  );
};
