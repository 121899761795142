import React from "react";
import JobCard from "../../components/JobCard/JobCard";
import BaseSalary from "./BaseSalary";
import { RecommendedCompanies } from "./RecommendedCompanies";
import medline_logo from "src/assets/images/medline-industries-logo.svg";
import sam_logo from "src/assets/images/samuel-logo.svg";
import jd_logo from "src/assets/images/jd-logo.svg";

export function JobsSection() {
  const jobs = [
    {
      id: 1,
      companyName: "Medline Industries",
      imageUrl: medline_logo,
      title: "Production Worker",
      description:
        "The Production Worker assembles customer orders from stock and places orders on pallets or shelves, or conveys orders to packing station or shipping department. Also sorts and places materials or items on racks, shelves, or in bins.",
      salary: '$20 - $28 /hr',
      roleType: 'Full-Time',
      location: 'New York, NY'
    },
    {
      id: 2,
      companyName: "Samuel and Samuel LLT",
      imageUrl: sam_logo,
      title: "Production / Assembly Line Worker",
      description:
        "Industrial bakery is seeking a cross-trainable production line worker. They will be responsible for making bread and placing them into trays. Lifting these 20-30lb trays will be an all day task and will need to be loaded onto racks.",
        salary: '$17/hr',
        roleType: 'Full-Time',
        location: 'Brooklyn, NY'
      },
    {
      id: 3,
      companyName: "JD Sweid Food",
      imageUrl: jd_logo,
      title: "General Laborer",
      description:
        "Industrial bakery is seeking a cross-trainable production line worker. They will be responsible for making bread and placing them into trays. Lifting these 20-30lb trays will be an all day task and will need to be loaded onto racks.",
        salary: '$680-$720/week',
        roleType: 'Part-Time',
        location: 'Brooklyn, NY'
      },
  ];
  return (
    <div className="m-6">
      <div className="text-left">
        <p className="text-2xl font-semibold">Jobs Recommended for You</p>
      </div>
      <div>
        <p className="text-left text-[#495057]">
          Recommendations are based on your job preferences and activity on
          Manpower
        </p>
      </div>
      <div className="mt-6 space-y-4">
        {jobs.map((job) => (
          <JobCard key={job.id} job={job} />
        ))}
      </div>
      <div className="text-left mt-20">
        <p className="text-2xl font-semibold">
          How much does a Factory Worker make?
        </p>
      </div>
      <div>
        <p className="text-left text-[#495057]">
          As of Mar 21, 2024, the average monthly pay for a Factory Worker in
          the United States is $2,922 a month.
        </p>
      </div>

      {/* Average base pay chart */}
      <div className="mt-6">
        <BaseSalary></BaseSalary>
        <div className="text-right mt-4">
          <a href={""} className="text-indigo-600 hover:underline">
            Search another salary {">"}
          </a>
        </div>
      </div>
      <div className="text-left mt-16">
        <p className="text-2xl font-semibold">Companies Recommended for You</p>
      </div>
      <RecommendedCompanies></RecommendedCompanies>
      <div className="text-right mt-4">
        <a href={""} className="text-indigo-600 hover:underline">
          Explore other companies {">"}
        </a>
      </div>
    </div>
  );
}
