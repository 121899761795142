// Import the createSlice function from Redux Toolkit
import { createSlice } from "@reduxjs/toolkit";

// Interface that represents a Job
export interface Job {
    id: number;
    company_name: string;
    company?: string;
    image_url: string;
    title: string;
    about_this_role: string;
    location?: string;
    salary_range?: string;
    about_us?: string;
    responsibilities?: string[];
}

const initialJobsArray: Job[] = [
    {
        id: 1,
        company_name: "Affinity",
        company: "",
        image_url: "affinity.png",
        title: "Warehouse Operations Administrator",
        about_this_role: "We are searching for a highly motivated and results-oriented Team Lead to join our growing automotive team. In this leadership role, you will play a critical part in ensuring the smooth and efficient operation of our warehouse department. You will be responsible for supervising a team of 25-30 employees, ensuring they have the resources and support needed to achieve their goals.",
        location: "Aurora, IL",
        salary_range: "$65,000 - $75,000",
        about_us: "Join our team at our Aurora, IL location and immerse yourself in an environment that is committed to your growth and development. Be a part of a customer-focused and growth-oriented company that offers more than just vehicle repairs.",
        responsibilities: [
            "Oversee the daily operations of the warehouse, including ",
            "Production, quality control, and inventory management.",
            "Lead, motivate, and coach a team of 25-30 employees.",
        ]
    }
];

// Define a new slice of the Redux store
export const jobsSlice = createSlice({
    // The name of the slice
    name: "jobs",
    // The initial state of the slice
    initialState: {
        recommendedJobs: initialJobsArray,
        selectedJobId: 1
    },
    // The reducers for this slice
    reducers: {
        // Each reducer takes the current state and an action, and returns the new state
        setRecommendedJobs: (state, action) => {
            state.recommendedJobs = action.payload;
        },
        setSelectedJobId: (state, action) => {
            state.selectedJobId = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const { setRecommendedJobs, setSelectedJobId } = jobsSlice.actions;

// Selectors allow you to access specific parts of the state
export const selectRecommendedJobs = (state: any) => state.jobs.recommendedJobs;
export const selectSelectedJobId = (state: any) => state.jobs.selectedJobId;

// Export the reducer for this slice, so it can be included in the store
export default jobsSlice.reducer;