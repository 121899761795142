import React, { useState } from "react";
import "./App.css";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  useRouteError,
} from "react-router-dom";
import awsconfig from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { withAuthenticator } from "@aws-amplify/ui-react";
import Outlay from "./pages/Outlay";
import Home from "./pages/Home/index";
import Jobs from "./pages/Jobs/Jobs";
import Landing from "./pages/Landing/Landing";
import Resume from "./pages/Resume";
import Interview from "./pages/Interview/Interview";

Amplify.configure(awsconfig);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Outlay />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Landing />,
      },
      {
        path: "/jobs",
        element: <Jobs />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/resume",
        element: <Resume />,
      },
      {
        path: "/interview",
        element: <Interview />,
      },
    ],
  },
]);

function ErrorBoundary() {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <div>Doh!</div>;
}

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default withAuthenticator(App);
