import React, { useState } from 'react';
import "./SideNav.css"
import { useLocation, useNavigate } from 'react-router-dom';
import menu_open from 'src/assets/images/menu_open.svg';
import menu_close from 'src/assets/images/menu_close.svg';
import id_card from 'src/assets/images/id_card.svg';
import cognition from 'src/assets/images/cognition.svg';
import id_card_black from 'src/assets/images/id_card_black.svg';
import cognition_black from 'src/assets/images/cognition_black.svg';

export default function SideNav() {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <div 
            className={`sideNavContainer border-r-[1px] border-neutral-300 px-4 py-8 flex-grow-0 flex-shrink-0 flex flex-col items-end justify-start bg-neutral-100 
                ${isExpanded? 'w-60' : 'w-28'}`
        }>
            {/* Expand Collapse Button */}
            <div className='w-10 flex justify-center cursor-pointer first:' onClick={() => setIsExpanded(!isExpanded)}>
                <img className="mb-7" src={isExpanded? menu_close : menu_open} alt="Expand / Collapse button"/>
            </div>

            {/* Menu Items */}

            {/* Resume Builder */}
            <div className={`cursor-pointer h-10 rounded-lg flex flex-row items-center p-3
                ${isExpanded? 'w-40 justify-start gap-2' : 'w-10 justify-center'}
                ${location.pathname === '/resume' ? 'bg-accent-green' : ''}`}
                onClick={() => {if(location.pathname !== '/resume') navigate('/resume')}}>
                <img src={location.pathname === '/resume' ? id_card : id_card_black} alt="Expand / Collapse button" />
                {isExpanded && (
                    <div className={`text-left text-sm
                        ${location.pathname === '/resume' ? 'text-white font-medium' : 'text-neutral-900 font-normal'}`}>Resume Builder</div>
                )}
            </div>

            {/* Interview Prep */}
            <div className={`cursor-pointer h-10 rounded-lg flex flex-row items-center p-3
                ${isExpanded? 'w-40 justify-start gap-2' : 'w-10 justify-center'}
                ${location.pathname === '/interview' ? 'bg-accent-green' : ''}`}
                onClick={() => {if(location.pathname !== '/interview') navigate('/interview')}}>
                <img src={location.pathname === '/interview' ? cognition : cognition_black} alt="Expand / Collapse button" />
                {isExpanded && (
                    <div className={`text-left text-sm
                        ${location.pathname === '/interview' ? 'text-white font-medium' : 'text-neutral-900 font-normal'}`}>Interview Prep</div>
                )}
            </div>
        </div>
    );
}