// Import the createSlice function from Redux Toolkit
import { createSlice } from "@reduxjs/toolkit";
import medline_logo from "src/assets/images/medline-industries-logo.svg";
import sam_logo from "src/assets/images/samuel-logo.svg";
// Define a new slice of the Redux store
export const counterSlice = createSlice({
  // The name of the slice
  name: "app",
  // The initial state of the slice
  initialState: {
    chatLog: [
      ,// { role: "assistant", content: "Hi, what can I help you with today?" },
    ],
  },
  // The reducers for this slice
  reducers: {
    // Each reducer takes the current state and an action, and returns the new state
    updateChatLog: (state, action) => {
      state.chatLog = action.payload;
    },
    addChatLog: (state, action) => {
      state.chatLog.push(action.payload);
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateChatLog, addChatLog } = counterSlice.actions;

// Selectors allow you to access specific parts of the state
export const selectChatLog = (state: any) => state.app.chatLog;

// Export the reducer for this slice, so it can be included in the store
export default counterSlice.reducer;
