import React, { useState } from "react";
import checked_box from "../../assets/images/check_mark_ticked.svg";
import unchecked_box from "../../assets/images/check_mark_UNticked.svg";
import close from "../../assets/images/close_small.svg";
import white_logo from "../../assets/images/white-brandig.svg";
import psych_icon from "../../assets/images/psychology_alt.svg";

const FilterList = () => {
  return (
    <div className="flex flex-col">
      <p>Job Type</p>
      <div className="flex flex-row">
        <img src={checked_box} />
        <p>Full-Time</p>
        <p>42</p>
      </div>
      <div className="flex flex-row">
        <img src={unchecked_box} />
        <p>Part-Time</p>
        <p>11</p>
      </div>
      <div className="flex flex-row">
        <img src={checked_box} />
        <p>Remote</p>
        <p>111</p>
      </div>
      <div className="flex flex-row">
        <img src={unchecked_box} />
        <p>Internship</p>
        <p>2</p>
      </div>
      <div className="flex flex-row">
        <img src={unchecked_box} />
        <p>Contract</p>
        <p>3</p>
      </div>
    </div>
  );
};

function FiltersMenu() {
  return (
    <div className="m-8 space-y-8 w-1/5">
      <div className="flex flex-col text-left">
        <div className="flex flex-row items-center justify-between">
          <p className="pb-8">Filters</p>
          <div className="flex flex-row items-center pb-8">
            <img className="w-6" src={close} />
            <p className="text-accent-blue text-sm font-normal">Clear All</p>
          </div>
        </div>
        <div className="flex flex-col text-base">
          <p className="pb-6 font-semibold">Job Type</p>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={checked_box} />
            <p>Full-Time</p>
            <p className="ml-auto">42</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Part-Time</p>
            <p className="ml-auto">11</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={checked_box} />
            <p>Remote</p>
            <p className="ml-auto">111</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Internship</p>
            <p className="ml-auto">2</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Contract</p>
            <p className="ml-auto">3</p>
          </div>

          <p className="pb-6 font-semibold mt-10">Salary Range</p>
          <div className="flex flex-row justify-between mb-5">
            <div>
              <p className="text-sm text-neutral-700 border-neutral-500 border border-solid py-2 px-4 rounded">
                Hourly
              </p>
            </div>
            <div>
              <p className="text-sm text-neutral-700 border-neutral-500 border border-solid py-2 px-4 rounded">
                Monthly
              </p>
            </div>
            <div>
              <p className="text-sm  text-accent-blue bg-accent-blue-light border border-solid py-2 px-4 rounded">
                Yearly
              </p>
            </div>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Under $25,000</p>
            <p className="ml-auto">24</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>$25,000 - $27,000</p>
            <p className="ml-auto">15</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>$27,000 - $30,000</p>
            <p className="ml-auto">56</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>$30,000 - $35,000</p>
            <p className="ml-auto">9</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>$35,000 and above</p>
            <p className="ml-auto">38</p>
          </div>

          <p className="pb-6 font-semibold mt-10">Date Posted</p>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={checked_box} />
            <p>Any</p>
            <p className="ml-auto">42</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Today</p>
            <p className="ml-auto">11</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Last 2 days</p>
            <p className="ml-auto">45</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Last week</p>
            <p className="ml-auto">123</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Last 2 weeks</p>
            <p className="ml-auto">145</p>
          </div>
          <div className="flex flex-row mb-4">
            <img className="mr-4" src={unchecked_box} />
            <p>Last month</p>
            <p className="ml-auto">145</p>
          </div>
        </div>
        <div className="mt-10 flex flex-col text-left bg-[#E5ECF680] rounded-lg border-neutral-300 border-1 py-8 px-6">
          <div className="flex flex-row items-center gap-2">
            <img src={psych_icon} />
            <p className="text-semibold">Fear Job Interview?</p>
          </div>
          <p className="mt-5 text-neutral-700">
            Don’t panic! Generate targeted questions for any job application.
          </p>
          <button className="text-sm py-2 px-8 mt-4 bg-accent-green rounded-lg text-white flex flex-row items-center gap-2 text-center text-semibold">
            <img className="w-6" src={white_logo} />
            Practice Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default FiltersMenu;
