import React, { useState } from 'react';

export default function ToggleButton(props: {disabled: boolean}) {
    const [isToggled, setIsToggled] = useState<boolean>(true);
    const { disabled } = props;

    const handleChange = () => {
        if (!disabled)
            setIsToggled(!isToggled);
    }

    return (
        <div onClick={handleChange} 
            className={`toggleBtn w-11 h-6 rounded-full px-[5px] py-1 flex justify-start 
                ${isToggled? 'bg-accent-green' : 'bg-neutral-500'}
                ${disabled? 'cursor-not-allowed': 'cursor-pointer'}`}>
            <div
                className={`toggleCircle w-4 h-4 bg-white rounded-full transition-transform ${
                    isToggled ? 'transform translate-x-full' : 'transform translate-x-0'
                }`}
            ></div>
        </div>
    );
}