import React, { useEffect } from "react";
import "./Navbar.css";
import manpower_logo from "../../assets/images/manpower_logo.png";
import manpower_logo_white from "src/assets/images/white-brandig.svg";
import arrow_drop_down from "../../assets/images/arrow-drop-down.svg";
import profile_pic from "../../assets/images/avatar.png";
import { useNavigate, useLocation } from "react-router-dom";
import sendMessages, { Action } from "../../services/WebsocketManager.ts";
import { useSession } from "src/context/SessionProvider.tsx";

const Navbar = ({ setChatOpen }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isChatAIPage =
    location.pathname === "/resume" || location.pathname === "/interview";
  const { sessionID } = useSession();

  // Mock API call
  useEffect(() => {
    const messageData = {
      action: "sendMessage",
      session_id: sessionID,
    };
    sendMessages(Action.CHAT, messageData);
  }, []);

  return (
    <div className="flex flex-row justify-between px-20 py-5 items-center border-b-[1px] border-solid border-neutral-300 sticky top-0 bg-white">
      <img
        className="cursor-pointer"
        src={manpower_logo}
        alt="Manpower Logo"
        onClick={() => navigate("/")}
      ></img>
      {isChatAIPage ? (
        <div className="text-xl font-semibold text-accent-green">Chat AI</div>
      ) : (
        <div className="flex flex-row gap-10">
          <div className="cursor-pointer" onClick={() => navigate("/home")}>
            Home
          </div>
          <div className="cursor-pointer" onClick={() => navigate("/jobs")}>
            Jobs
          </div>
          <div className="cursor-not-allowed">Companies</div>
          <div className="cursor-not-allowed">Careers</div>
        </div>
      )}
      <div className="flex flex-row gap-2">
        <button
          onClick={() => setChatOpen(true)}
          className="flex items-center justify-center gap-x-1.5 px-2.5 py-2 rounded-lg bg-[var(--Accent-Green,#3B8160)] text-white text-base font-semibold leading-normal"
        >
          <img src={manpower_logo_white} alt="Manpower Logo White" />
          Chat AI
        </button>
        <div className="flex flex-row items-center">
          <img src={profile_pic} alt="Profile Pic"></img>
          <img src={arrow_drop_down} alt="Arrow down"></img>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
