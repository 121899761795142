import React from "react";
import medline_logo from "src/assets/images/medline-industries-logo.svg";
import affinity_logo from "src/assets/images/affinity-logo.svg";
import jd_logo from "src/assets/images/jd-logo.svg";
import calendar_icon from "src/assets/images/calendar_today.svg";
import bl_logo from "src/assets/images/b&l_logo.svg";
import willcox_logo from "src/assets/images/willcox_logo.svg";
import levain_bakery_logo from "src/assets/images/levain_bakery_logo.svg";
import pandg_logo from "src/assets/images/p&g_logo.svg";

const logoMap: any = {
  Affinity: affinity_logo,
  "Medline Industries": medline_logo,
  "Willcox Matthews": willcox_logo,
  "Bausch + Lomb": bl_logo,
  "Levain Bakery": levain_bakery_logo,
  "Procter and Gamble": pandg_logo,
  "JD Sweid Food": jd_logo,
};
const JobCard = ({ job, handleJobCardClick }: any) => {
  const companyLogo = logoMap[job.company_name];

  const renderLogo = companyLogo ? (
    <img
      src={companyLogo}
      alt={`${job.company_name} Logo`}
      className="w-8 h-8 object-cover rounded-lg mr-4"
    />
  ) : (
    <div className="w-8 h-8 bg-black rounded-lg mr-4 flex justify-center items-center text-white">
      ?
    </div>
  );

  const handleClick = () => {
    handleJobCardClick(job);
  };

  return (
    <div
      className="w-full h-fit bg-white shadow-md rounded-lg flex flex-col p-6 border border-gray-200 hover:border-gray-500 cursor-pointer"
      onClick={handleClick}
    >
      {/* Top section with logo, company name, and calendar icon */}
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          {renderLogo}
          <span className="text-xs text-black font-light">
            {job.company_name}
          </span>
        </div>
        <div className="flex items-center">
          <img className="h-3 mr-2" src={calendar_icon} alt="Calendar" />
          <span className="text-gray-600 text-xs whitespace-nowrap">
            5 hours ago
          </span>
        </div>
      </div>

      {/* Job title and description */}
      <div className="flex-grow">
        <h3 className="text-xl text-left text-black font-semibold">
          {job.title}
        </h3>
        <p className="text-gray-700 text-left mt-2 text-sm">
          {job.description}
        </p>
      </div>

      {/* Tags */}
      <div className="mt-8 flex justify-between items-center">
        <div className="flex flex-wrap mb-0">
          {" "}
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            {job.salary_range}
          </span>
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            Full-Time
          </span>
          <span
            className="bg-neutral-100 rounded text-sm text-gray-700 mr-2 py-2 px-4"
            style={{ borderRadius: "4px", backgroundColor: "#F5F5F5" }}
          >
            {job.location}
          </span>
        </div>
      </div>

      {/* Replace this section with your own buttons if needed */}
    </div>
  );
};

export default JobCard;
