import React, { useState } from "react";
import jobsImage from "../../assets/images/MPG1.png";



function FindJobs() {

  return (
    <div className="max-w-full flex flex-col center p-16 bg-gray-100 gap-12">
      <div className="flex flex-col place-items-center gap-4 mb-9">
        <h1 className="font-bold text-neutral-900 text-4xl">Find jobs in 3 easy steps</h1>
        <h4 className="font-normal text-neutral-700 text-lg w-3/5">Land your next job in 3 steps! We’ll match your skills to top openings, guide you through applications, and help you shine in interviews. It's that easy!</h4>
      </div>
      <div className="mx-auto flex flex-row gap-8 items-center">
        <img className="rounded-[10px]" src={jobsImage} />
        <div className="flex flex-col gap-14">
          <div className="flex flex-row gap-4">
            <div className=" w-11 h-11 shrink-0 grow-0 rounded-full bg-brand-green flex justify-center items-center"><p className="text-white">1</p></div>
            <div className="flex flex-col gap-4 text-left">
              <p className="font-bold text-2xl">Find a job</p>
              <p className="text-lg text-neutral-700">Don't just search, get matched! Explore hundreds of opportunities!</p>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-11 h-11 shrink-0 grow-0 rounded-full bg-brand-green flex justify-center items-center"><p className="text-white">2</p></div>
            <div className="flex flex-col gap-4 text-left">
              <p className="font-bold text-2xl">Apply on our website</p>
              <p className="text-lg text-neutral-700">Create an account, upload your resume and easily track your application process. </p>
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="w-11 h-11 shrink-0 grow-0 rounded-full bg-brand-green flex justify-center items-center"><p className="text-white">3</p></div>
            <div className="flex flex-col gap-4 text-left">
              <p className="font-bold text-2xl">Get interview call</p>
              <p className="text-lg text-neutral-700">Boost your chances of getting noticed by hiring managers.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FindJobs;