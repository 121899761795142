import React, { useState } from "react";

interface HeaderProps {
  changeView: any;
}

function Header({ changeView }: HeaderProps) {
  const [focus, setFocus] = useState({ what: false, where: false });
  return (
    <div className="max-w-full px-16 bg-accent-blue-light text-left">
      <div className="mx-auto flex flex-col items-center text-center pt-12 px-20 bg-accent-blue-light rounded-[10px]">
        <h3 className="font-bold text-xl mb-6">Find a Perfect Job</h3>
        <form className="bg-white w-full mx-auto rounded-[10px]" role="search">
          <div className="px-6 py-3 flex flex-row justify-between gap-y-88">
            <input
              className="w-full pl-2"
              placeholder={
                focus.what
                  ? "Search for roles, keywords, companies"
                  : "What? Search for roles, keywords, companies"
              }
              name="srch-term"
              id="srch-term1"
              type="text"
              onFocus={() => setFocus({ ...focus, what: true })}
              onBlur={() => setFocus({ ...focus, what: false })}
            />
            {/* <div className="border-1 border-black"></div> */}
            <input
              className="w-full pl-2"
              placeholder={
                focus.where
                  ? "Enter location or “remote”"
                  : "Where? Enter location or “remote”"
              }
              name="srch-term"
              id="srch-term2"
              type="text"
              onFocus={() => setFocus({ ...focus, where: true })}
              onBlur={() => setFocus({ ...focus, where: false })}
            />

            <button
              className="bg-accent-blue text-nowrap py-4 px-8 rounded-lg text-white"
              type="submit"
            >
              Find Jobs
            </button>
          </div>
        </form>
        <div className="flex flex-row gap-4 mt-10 mb-2">
          <p
            className="text-accent-blue hover:underline"
            onClick={() => changeView(1)}
          >
            Jobs for you
          </p>
          <p className="text-accent-blue hover:underline">Saved</p>
          <p
            className="text-accent-blue hover:underline"
            onClick={() => changeView(3)}
          >
            Applied
          </p>
        </div>
      </div>
    </div>
  );
}

export default Header;
