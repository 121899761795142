// Imports
import React, {useState} from "react";
import ResumeCard from "../components/ResumeCard/ResumeCard";
import RoleCard from "../components/RoleCard/RoleCard.tsx";
import ToggleButton from "../components/ToggleButton.tsx";
import SideNav from "../components/SideNav/SideNav.tsx";
import upload from "../assets/images/upload.svg";
import linkedin from "../assets/images/lined in.svg";
import edit_note from "../assets/images/edit_note.svg";
import cognition_lg from "../assets/images/cognition_lg.svg";
import { useNavigate } from "react-router-dom";

// Page component to show the Resume Builder
function Resume() {
    // To show/hide the resume import screen
    const [resumeImported, setResumeImported] = useState<boolean>(false);
    const [resumeSaved, setResumeSaved] = useState<boolean>(false);
    const [newResume, setNewResume] = useState<boolean>(false);
    const navigate = useNavigate();

    return (
        <div className="pageContainer w-full flex flex-row items-stretch">
            <SideNav />
            {resumeImported || newResume? (
                resumeSaved? (
                <div className="resumeImportPage p-20 text-neutral-900 flex flex-col items-center bg-neutral-100 flex-1 gap-7">
                    <div className="resumeImportHeader font-bold text-4xl h-[74px]">What would you like to do next?</div>
                    <div className="optionsCard w-fit h-fit p-14 pt-8 bg-white rounded flex flex-col items-center justify-start gap-9">
                        <div className="optionsContainers w-fit h-fit flex flex-row justify-between items-center gap-8">
                            <div className="optionButton w-[264px] h-[232px] bg-neutral-50 rounded-2xl flex flex-col items-center justify-center hover:border hover:border-neutral-700 cursor-pointer" onClick={() => {setResumeSaved(false);}}>
                                <img src={edit_note} alt="Build a new resume" className="mb-3"/>
                                <div className="font-medium text-lg text-black">Back to Resume</div>
                                <div className="font-normal text-sm text-neutral-700">Make edits to the resume</div>
                            </div>
                            <div className="optionButton w-[264px] h-[232px] bg-neutral-50 rounded-2xl flex flex-col items-center justify-center hover:border hover:border-neutral-700 cursor-pointer" onClick={() => navigate('/interview')}>
                                <img src={cognition_lg} alt="Prepare for interview" className="mb-3"/>
                                <div className="font-medium text-lg text-black">Prepare for an interview</div>
                                <div className="font-normal text-sm text-neutral-700">Just enter the job title</div>
                            </div>
                        </div>
                    </div>
                </div>
                ): (
                <div className="resumePage p-20 text-neutral-900 flex flex-col items-center bg-neutral-100 flex-1">
                    <div className="resumeHeader font-bold text-4xl h-[74px]">Resume Builder</div>
                    <div className="resumeSubHeader font-medium text-base text-center text-wrap mt-2 max-w-[644px]">Click to edit any section. Review the suggested changes and decide whether you want to keep it or make further changes.</div>
                    <div className="resumePageContent mt-[68px] w-full flex flex-row gap-16 justify-center">
                        <ResumeCard setResumeSaved={setResumeSaved} newResume={newResume} />
                        <div className="resumeRightColumn flex flex-col gap-5 justify-start w-[342px]">
                            <div className="targetResume flex flex-row justify-start items-center gap-3 font-semibold text-lg text-black">
                                Target Your Resume
                                <ToggleButton disabled/>
                            </div>
                            <div className="targetDesc font-normal text-xs text-neutral-700 text-left">
                                A targeted resume is all about tailoring it to the job you want. By showing you have the right experience for the position, you're more likely to score an interview.
                            </div>
                            <RoleCard />
                        </div>
                    </div>
                </div>
                )
            ) : (
                <div className="resumeImportPage p-20 text-neutral-900 flex flex-col items-center bg-neutral-100 flex-1 gap-7">
                    <div className="resumeImportHeader font-bold text-4xl h-[74px]">How would you like to start?</div>
                    <div className="optionsCard w-fit h-fit p-14 pt-8 bg-white rounded flex flex-col items-center justify-start gap-9">
                        <div className="optionsParagraph w-full text-sm font-normal text-neutral-700">Feel free to create more than one resume. We’ll help you adjust it to the job opening.</div>
                        <div className="optionsContainers w-fit h-fit flex flex-row justify-between items-center gap-8">
                            <div className="optionButton w-[264px] h-[232px] bg-neutral-50 rounded-2xl flex flex-col items-center justify-center hover:border hover:border-neutral-700 cursor-pointer" onClick={() => setNewResume(true)}>
                                <img src={edit_note} alt="Build a new resume" className="mb-3"/>
                                <div className="font-medium text-lg text-black">Build a new resume</div>
                                <div className="font-normal text-sm text-neutral-700">We’ll guide you step-by-step</div>
                            </div>
                            <div className="optionButton w-[264px] h-[232px] bg-neutral-50 rounded-2xl flex flex-col items-center justify-center hover:border hover:border-neutral-700 cursor-not-allowed">
                                <img src={linkedin} alt="LinkedIn" className="mb-10"/>
                                <div className="font-medium text-lg text-black">Import from LinkedIn</div>
                                <div className="font-normal text-sm text-neutral-700">Use your LinkedIn Profile</div>
                            </div>
                            <div className="optionButton w-[264px] h-[232px] bg-neutral-50 rounded-2xl flex flex-col items-center justify-center hover:border hover:border-neutral-700 cursor-pointer" onClick={() => setResumeImported(true)}>
                                <img src={upload} alt="Upload resume" className="mb-3"/>
                                <div className="font-medium text-lg text-black">Use saved resume</div>
                                <div className="font-normal text-sm text-neutral-700">We’ll pre-fill your details</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Resume;